import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import UserSchedule from "./components/pages/UserSchedule";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import Login from "./components/shared/Login";
import Loader from "./components/shared/Loader";
import { AuthContext } from "react-oauth2-code-pkce";
import { getAuthDetails } from "./features/authDetailsReducer/action";
import { getUserStoreData } from "./features/userStoreReducer/action";
import AccessDenied from "./components/pages/AccessDenied";
import { useAppDispatch, useAppSelector } from "./redux/store";
import ErrorPage from "./components/shared/ErrorPage";
import { useTranslation } from "react-i18next";
import { LOCAL_STORAGE_LANGUAGE } from "./components/GlobalConstants";
import UserProfile from "./components/shared/UserProfile";
import { AnalyticsContext, PageType } from "./analytics";
import NewUserDialog from "./components/shared/NewUserDialog";
import { setIsNewUser } from "./features/metaReducer";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { loginInProgress, error: errorDuringSSO } = useContext(AuthContext);
  const { i18n } = useTranslation();
  const [empID, setEmpID] = useState<any>();
  const [empError, setEmpError] = useState<boolean>(false);
  const [profileError, setProfileError] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [userIsAuthorized, setUserIsAuthorized] = useState<boolean>(false);
  const [loadData, setLoadData] = useState<boolean>(true);
  const [showAccessDeniedPage, setShowAccessDeniedPage] =
    useState<boolean>(false);
  const analytics = useContext(AnalyticsContext);
  const searchParams = new URLSearchParams(document.location.search);

  document.documentElement.lang = i18n.language;

  useEffect(
    () =>
      analytics?.trackPageView({
        name: "App",
        pageType: PageType.Document,
        refUri: searchParams.get("ref") ?? undefined,
      }),
    [],
  );

  const { infoClicked, isNewUser } = useAppSelector((state: any) => state.meta);
  const isSSO = process.env.REACT_APP_IS_SSO_ENABLED === "true";
  const {
    userAuthDetails,
    loadingAuthData,
    success: authApiSuccess,
  } = useAppSelector((state: any) => state?.authDetails);
  const { storeData, success: storeSuccess } = useAppSelector(
    (state: any) => state?.userStoreData,
  );

  useEffect(() => {
    empFunction();
  }, []);

  useEffect(() => {
    const empId = Number(localStorage.getItem("userEmployeeId"));
    if (localStorage.getItem("userLoggedIn") === null) {
      dispatch(setIsNewUser(true));
    }
    if (!isSSO) {
      if (empId) {
        void dispatch(getUserStoreData(empId));
      }
    }
  }, []);

  useEffect(() => {
    if (storeSuccess) {
      setLoadData(false);
      setShowAccessDeniedPage(!storeData?.allowed);
      if (!localStorage.getItem(LOCAL_STORAGE_LANGUAGE)) {
        // Set language from home store location if a local preference has not been set already.
        i18n
          .changeLanguage(storeData.preferredLanguage)
          .then(() => {
            analytics?.trackEvent({
              name: `Success: set language`,
              properties: {
                page: "App",
                workflow: "View",
                component: "App",
              },
            });
            localStorage.setItem(
              LOCAL_STORAGE_LANGUAGE,
              storeData.preferredLanguage,
            );
          })
          .catch((e: any) => {
            analytics?.trackEvent({
              name: `Error: set language`,
              properties: {
                page: "App",
                workflow: "View",
                component: "App",
                error: e,
              },
            });
            console.error(
              "An error has occurred while setting language from user store",
              e,
            );
          });
      }

      // Set analytics data for tracking unique ID, session ID, and user role.
      const loginName = storeData?.loginName;
      if (loginName) {
        // setAuthenticatedUserContext requires that the user ID not contain any of the characters `,;=|`.
        const cleanedLoginName = loginName.toString().replace(/[,;=| ]+/g, "_");
        analytics?.setAuthenticatedUserContext(
          cleanedLoginName,
          undefined,
          true,
        );
        // Check if we have the Partner's title and include it in analytic events.
        analytics?.addTelemetryInitializer((item) => {
          if (item?.data && storeData?.title) {
            item.data.userTitle = storeData.title;
          }
        });
      }
    }
  }, [storeSuccess, storeData]);

  useEffect(() => {
    empFunction();
  }, [empID, showModal]);

  useEffect(() => {
    void getAuthentication();
  }, [loginInProgress]);

  const empFunction = () => {
    const isEmpId = localStorage.getItem("userEmployeeId");
    if (!isEmpId || isEmpId === "null") {
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (authApiSuccess) {
      setUserIsAuthorized(true);
    } else {
      setUserIsAuthorized(false);
    }
  }, [authApiSuccess]);

  const getAuthentication = async () => {
    if (
      userAuthDetails === null &&
      !loadingAuthData &&
      isSSO &&
      !loginInProgress
    ) {
      void (await dispatch(getAuthDetails()).then((res: any) => {
        if (res.payload && res.payload > 0) {
          setProfileError(false);
          analytics?.trackEvent({
            name: `Success: fetched auth details`,
            properties: {
              page: "App",
              workflow: "View",
              component: "App",
            },
          });
          const empId = Number(localStorage.getItem("userEmployeeId"));
          if (empId) {
            void dispatch(getUserStoreData(empId));
          }
        } else {
          setProfileError(true);
        }
      }));
    }
  };

  const submitEmpId = () => {
    if (empID?.length < 7) {
      setEmpError(true);
      return;
    }
    setEmpError(false);
    setShowModal(false);
    localStorage.setItem("userEmployeeId", empID);
    window.location.reload();
  };

  const handleChange = (e: any) => {
    const inputVal = e.target.value;
    const numberRegex = /^\d{0,7}$/;
    const isValid = numberRegex.test(inputVal);
    setEmpError(false);
    if (!isValid) {
      return;
    } else {
      setEmpID(inputVal);
    }
  };

  if (errorDuringSSO ?? profileError) {
    analytics?.trackEvent({
      name: `Error: Auth error`,
      properties: {
        page: "App",
        workflow: "View",
        component: "App",
      },
    });
    return <ErrorPage />;
  }

  return (
    <div className={"App"}>
      {(loadData && !showModal) ||
      !localStorage.getItem(LOCAL_STORAGE_LANGUAGE) ? (
        <Loader fullScreen={true} />
      ) : (
        <SimpleBar className="fullHeight" tabIndex={-1}>
          {showModal && !isSSO && !loadingAuthData ? (
            <Login
              handleChange={handleChange}
              submitEmpId={submitEmpId}
              empID={empID}
              empError={empError}
            />
          ) : (
            localStorage.getItem("userEmployeeId") &&
            userIsAuthorized && (
              <Fragment>
                {(isNewUser || infoClicked) && !showAccessDeniedPage && (
                  <NewUserDialog />
                )}
                {!showAccessDeniedPage && (
                  <Router>
                    <Routes>
                      <Route path="/" element={<UserSchedule />} />
                      <Route path="/profile" element={<UserProfile />} />
                      {/* default redirect to home page */}
                      <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                  </Router>
                )}
                {showAccessDeniedPage === true && <AccessDenied />}
              </Fragment>
            )
          )}
        </SimpleBar>
      )}
    </div>
  );
};

export default App;
