import React, { useContext, useState } from "react";
import { Alert, Grid, Snackbar, Stack } from "@mui/material";
import CustomAccordion from "../../../../common/Accordion";
import RequestAccContent from "../RequestAccContent";
import { getTotalHrs } from "../../../../../utils/durationCalculate";
import RequestAccHeader from "../RequestAccHeader";
import { getMonthTranslations } from "../../../../../utils/getDays";
import { AccessTime } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../../../redux/store";
import { updateAvailableRequest } from "../../../../../features/availabilityRequestReducer/availabilityActions";
import DeleteModel from "./DeleteModal";
import TypoGraphy from "../../../../common/Typography";
import "./styles.scss";
import { CheckCircle } from "../../../../../styles/assets/svg/SVGIcons";
import editPending from "../../../../../styles/assets/svg/editPending.svg";
import trash from "../../../../../styles/assets/svg/trash.svg";
import { deleteAvailabilityRequest } from "../../../../../features/availabilityRequestReducer/action";
import ErrorIcon from "@mui/icons-material/Error";
import Loader from "../../../../shared/Loader";
import { AnalyticsContext } from "../../../../../analytics";
import Create from "../../Create";
import CustomIcon from "../../../../common/Icon";
import { AVAILABILITY_MAX_DAY_FOR_CREATE } from "../../../../GlobalConstants";
import {
  setEditPendingAvailability,
  setEditPendingAvailabilityData,
} from "../../../../../features/metaReducer";
import { defaultWeeklyDays } from "../../Create/Constant";
import { returnAvailabilityToCancelPostEdit } from "../../../../../utils/availabilityUpdate";
import { getUserStoreHours } from "../../../../../features/userStoreReducer/action";
import dayjs from "dayjs";

interface Props {
  genAvailData: any[];
  expandedId: number | null;
  onAccordionChange: (id: number) => void;
}

const PendingRequests: React.FC<Props> = ({
  genAvailData,
  expandedId,
  onAccordionChange,
}) => {
  genAvailData?.sort(
    (a, b) =>
      new Date(a.effectiveFrom).getTime() - new Date(b.effectiveFrom).getTime(),
  );

  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { availabilityRequestData } = useAppSelector(
    (state: any) => state.availabilityAll,
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [requestFailed, setRequestsFailed] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState<string | null>(
    null,
  );
  const [selectedStartDate, setSelectedStartDate] = useState<string | null>(
    null,
  );
  const [selectedEndDate, setSelectedEndDate] = useState<string | null>(null);
  const [openSnack, setOpenSnack] = useState<boolean>(false); // Initialize with false
  const [isLoading, setIsLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [comment, setComment] = useState<string>("");
  const analytics = useContext(AnalyticsContext);
  const editPendingAvailabilityData = useAppSelector(
    (state: any) => state.meta.editPendingAvailabilityData,
  );

  const { onHomeScreen } = useAppSelector((state: any) => state.meta);
  const { remainingDays } = getMonthTranslations();

  const updatedWeekDaysStore = useAppSelector(
    (state) => state.updateWeekdays.updatedDays,
  );

  const { storeData } = useAppSelector((state: any) => state?.userStoreData);

  const openDeleteModal = (
    id: string,
    startDate: string,
    endDate: string,
    comment: any,
  ) => {
    setOpenModal(true);
    setComment(comment);
    setSelectedRequestId(id);
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const handleDelete = async () => {
    if (!selectedRequestId) {
      return;
    }
    setIsLoading(true);
    closeModal();
    await dispatch(
      deleteAvailabilityRequest(
        returnAvailabilityToCancelPostEdit(
          defaultWeeklyDays,
          updatedWeekDaysStore,
          comment,
          selectedStartDate,
          selectedEndDate,
          selectedRequestId,
          true,
          false,
          editPendingAvailabilityData,
        ),
      ),
    )
      .then((action) => {
        const response = action.payload;
        if (response.data) {
          setRequestsFailed(false);
          setIsLoading(false);
          const updatedAvailabilityRequestData =
            availabilityRequestData?.availabilityRequests.map((item: any) => {
              if (item.id === selectedRequestId && item.status === "Pending") {
                return {
                  ...item,
                  status: response.data.status,
                };
              }
              return item;
            });

          dispatch(
            updateAvailableRequest({
              ...availabilityRequestData,
              availabilityRequests: updatedAvailabilityRequestData,
            }),
          );

          setOpenSnack(true);
        } else {
          setIsLoading(false);
          analytics?.trackEvent({
            name: `Error: API error`,
            properties: {
              page: "Availability",
              workflow: "View",
              component: "PendingRequests",
              error: response,
            },
          });
          console.error("API Error:", response.success);
        }
      })
      .catch(() => {
        setOpenSnack(!openSnack);
        setRequestsFailed(true);
        setIsLoading(false);
      });
  };

  const closeModal = () => {
    setOpenModal(!openModal);
    setSelectedRequestId(null);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  const handleClose = () => {
    setOpenSnack(false);
  };

  const toggleDrawer = (open: boolean) => {
    analytics?.trackEvent({
      name: `Toggled ${open ? "open" : "closed"}`,
      properties: {
        page: "Availability",
        workflow: "View",
        component: "Drawer",
      },
    });
    setOpenDrawer(open);
  };

  return (
    <>
      <Grid item xs={12}>
        {genAvailData?.map((availData: any) => (
          <Stack className="pending-request-wrapper" key={availData.id}>
            <CustomAccordion
              iconClass={"acc-icon"}
              accordionClass={"acc-wrapper acc-requests pending-requests"}
              headClass={"acc-header"}
              contentClass={"acc-content"}
              headContent={
                <Stack>
                  <RequestAccHeader
                    averageHrs={getTotalHrs(availData?.availabilities)}
                    startDate={availData?.effectiveFrom}
                    endDate={availData?.endsAfter}
                    statusText={translate("PENDING")}
                    statusClass={"warning"}
                  />
                  <Grid xs={8} item className={"kick-status"}>
                    <Stack alignItems={"center"} direction={"row"}>
                      <CustomIcon IconComponent={AccessTime} />
                      <TypoGraphy variant="body1" typeClass={"regular-font"}>
                        {`${translate("STARTING_IN")} ${remainingDays(availData.effectiveFrom)} ${translate("DAYS")}`}
                      </TypoGraphy>
                    </Stack>
                  </Grid>
                </Stack>
              }
              details={
                <RequestAccContent
                  availabilities={availData?.availabilities}
                  comments={availData?.comments}
                  requestStatus={availData?.status}
                />
              }
              expanded={expandedId === availData.id}
              onChange={() => onAccordionChange(availData.id)}
            />

            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"stretch"}
            >
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent={"center"}
                className={
                  remainingDays(availData.effectiveFrom) >=
                  AVAILABILITY_MAX_DAY_FOR_CREATE
                    ? "pending-edit-btn"
                    : "pending-cancel-btn"
                }
                tabIndex={0}
                aria-label={translate("PENDING_REQUEST_CANCEL_BUTTON")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    openDeleteModal(
                      availData.id,
                      availData?.effectiveFrom,
                      availData?.endsAfter,
                      availData.comments[0].comment,
                    );
                    analytics?.trackEvent({
                      name: `Cancel pending button click`,
                      properties: {
                        page: "Availability",
                        workflow: "View requests",
                        component: "Pending Requests",
                      },
                    });
                  }
                }}
                onClick={() => {
                  analytics?.trackEvent({
                    name: `Cancel pending button click`,
                    properties: {
                      page: "Availability",
                      workflow: "View requests",
                      component: "Pending Requests",
                    },
                  });
                  openDeleteModal(
                    availData.id,
                    availData?.effectiveFrom,
                    availData?.endsAfter,
                    availData.comments[0].comment,
                  );
                }}
              >
                <img src={trash}></img>
                <TypoGraphy variant={"body1"} id="textPendingBtn">
                  {translate("CANCEL")}
                </TypoGraphy>
              </Stack>
              {remainingDays(availData.effectiveFrom) >=
                AVAILABILITY_MAX_DAY_FOR_CREATE && (
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent={"center"}
                  className="pending-edit-btn"
                  tabIndex={0}
                  aria-label={translate("PENDING_REQUEST_EDIT_BUTTON")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      toggleDrawer(true);
                      dispatch(setEditPendingAvailability(true));
                      dispatch(setEditPendingAvailabilityData(availData));
                      if (availData.effectiveFrom) {
                        void dispatch(
                          getUserStoreHours({
                            date: dayjs(availData.effectiveFrom).format(
                              "YYYY-MM-DD",
                            ),
                            locationID: storeData.locationInfo.locationNbr,
                          }),
                        );
                      }
                      analytics?.trackEvent({
                        name: `Edit pending button click`,
                        properties: {
                          page: "Availability",
                          workflow: "View requests",
                          component: "Pending Requests",
                        },
                      });
                    }
                  }}
                  onClick={() => {
                    toggleDrawer(true);
                    dispatch(setEditPendingAvailability(true));
                    dispatch(setEditPendingAvailabilityData(availData));
                    if (availData.effectiveFrom) {
                      void dispatch(
                        getUserStoreHours({
                          date: dayjs(availData.effectiveFrom).format(
                            "YYYY-MM-DD",
                          ),
                          locationID: storeData.locationInfo.locationNbr,
                        }),
                      );
                    }
                    analytics?.trackEvent({
                      name: `Edit pending button click`,
                      properties: {
                        page: "Availability",
                        workflow: "View requests",
                        component: "Pending Requests",
                      },
                    });
                  }}
                >
                  <img src={editPending} />
                  <TypoGraphy variant={"body1"} id="textPendingBtn">
                    {translate("EDIT")}
                  </TypoGraphy>
                </Stack>
              )}
            </Stack>
          </Stack>
        ))}
      </Grid>

      {isLoading && <Loader fullScreen={true} />}

      <DeleteModel
        openModal={openModal}
        closeModal={closeModal}
        startDate={selectedStartDate ?? undefined}
        endDate={selectedEndDate ?? undefined}
        handleDelete={handleDelete}
      />

      <Snackbar
        open={openSnack && onHomeScreen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className={requestFailed ? "snack-bar error" : "snack-bar success"}
      >
        <Alert
          onClose={handleClose}
          icon={requestFailed ? <ErrorIcon /> : <CheckCircle />}
          severity="success"
          variant="filled"
        >
          {requestFailed
            ? translate("INTERNAL_SERVER_ERROR")
            : translate("REQUEST_CANCELLED")}
        </Alert>
      </Snackbar>
      {openDrawer && <Create toggleDrawer={toggleDrawer} />}
    </>
  );
};

export default PendingRequests;
