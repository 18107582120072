import React, { useContext, useEffect, useState } from "react";
import { Grid, IconButton, Stack } from "@mui/material";
import TypoGraphy from "../../../common/Typography";
import { Close } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import updateLocale from "dayjs/plugin/updateLocale";
import "../../../../styles/drawer.scss";
import "../../../../styles/calendar.scss";
import "../style.scss";
import WarningTip from "./WarningTip";
import { useAppSelector } from "../../../../redux/store";
import "dayjs/locale/fr";
import { YearCalendar } from "@mui/x-date-pickers/YearCalendar";
import { BUTTON, PENDING } from "../../../GlobalConstants";
import moment from "moment";
import { AnalyticsContext } from "../../../../analytics";
import CalendarIconEnabled from "../../../../styles/assets/svg/calendar.svg";
import CalendarIconDisabled from "../../../../styles/assets/svg/calendarDisabled.svg";

interface Props {
  showStartDateCalendar: boolean;
  setShowStartDateCalendar: (showStartDateCalendar: boolean) => void;
  showEndDateCalendar: boolean;
  setShowEndDateCalendar: (showEndDateCalendar: boolean) => void;
  startDate?: Date | null;
  endDate?: Date | null | string;
  handleStartDate: (e: any) => void;
  handleEndDate: (e: any) => void;
  onStartDateDisabled: (disabled: boolean) => void;
  onEndDateDisabled: (disabled: boolean) => void;
  onSetEndDate: (date: string | null) => void;
  onSetStartDate: (date: string) => void;
  isStartAndNullEndDateExist: boolean;
  setIsStartAndNullEndDateExist: (disabled: boolean) => void;
  allowFromDays?: string;
}

interface FutureRequest {
  id: number;
  numberOfWeeks: number;
  startDate: string;
  endDate: string;
  weeklyAvailabilityResponseList: any[];
}

const DatePick: React.FC<Props> = ({
  showStartDateCalendar,
  setShowStartDateCalendar,
  showEndDateCalendar,
  setShowEndDateCalendar,
  startDate,
  endDate,
  handleStartDate: propHandleStartDate,
  handleEndDate: propHandleEndDate,
  onSetStartDate,
  onStartDateDisabled,
  onEndDateDisabled,
  onSetEndDate,
  isStartAndNullEndDateExist,
  setIsStartAndNullEndDateExist,
  allowFromDays,
}) => {
  dayjs.extend(updateLocale);
  dayjs.updateLocale("en", {
    weekStart: 1,
  });
  const { t: translate, i18n } = useTranslation();
  const [strikeDays, setStrikeDays] = useState<Date[]>([]);
  const [hightLightDays, setHightLightDays] = useState<Date[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [showYearCalendar, setShowYearCalendar] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);
  const [endSelectedDate, setEndSelectedDate] = useState<dayjs.Dayjs | null>(
    null,
  );
  const [initialSelectionComplete, setInitialSelectionComplete] =
    useState<boolean>(false);
  const [initialSelectionEndComplete, setInitialSelectionEndComplete] =
    useState<boolean>(false);
  const [approvedStartDateExists, setApprovedStartDateExists] =
    useState<boolean>(false);
  const [endDateCleared, setEndDateCleared] = useState<boolean>(false);
  const analytics = useContext(AnalyticsContext);

  const { availabilityRequestData, availabilityData } = useAppSelector(
    (state: any) => state.availabilityAll,
  );

  const editPendingAvailability = useAppSelector(
    (state: any) => state.meta.editPendingAvailability,
  );

  const editPendingAvailabilityData = useAppSelector(
    (state: any) => state.meta.editPendingAvailabilityData,
  );

  const futureAvailabilities = availabilityData.futureAvailabilities;

  const latestFutureAvailableEntry = futureAvailabilities
    .slice()
    .sort((a: FutureRequest, b: FutureRequest) =>
      moment(a.startDate).diff(moment(b.startDate)),
    );

  dayjs.locale(i18n.language);

  const shouldDisableStartDate = (date: any) => {
    const today = dayjs().startOf("day");
    const endDate = today.add(1, "year").endOf("day");
    return date.isAfter(endDate, "day");
  };

  const shouldDisableStartDateCalendarMonth = (date: any) => {
    const today = dayjs()?.startOf("day");
    const endDate = today?.add(1, "year")?.endOf("month");
    return date?.isAfter(endDate, "month");
  };

  const shouldDisableEndDateCalendarMonth = (date: any) => {
    if (!date || !startDate) return false;
    const endDate = dayjs(startDate)?.add(1, "year")?.endOf("month");
    return date?.isAfter(endDate, "month");
  };

  useEffect(() => {
    const startDay = moment(startDate).format("YYYY-MM-DD");
    const endDay = moment(endDate).format("YYYY-MM-DD");
    const diffDays = moment(endDay).diff(startDay, "day");
    const groupDays = [];

    for (let i = 0; i <= diffDays; i++) {
      groupDays.push(moment(startDay)?.add(i, "day").toDate());
    }
    setHightLightDays(groupDays);
  }, [startDate, endDate]);

  const returnInitialSelectDate = () => {
    const today = moment(new Date());
    let selectedDate = null;

    if (futureAvailabilities.length === 0) {
      selectedDate = dayjs(today.add(Number(allowFromDays), "days").toDate());
    }
    if (futureAvailabilities.length !== 0) {
      const latestApprovedDate = moment(
        latestFutureAvailableEntry[latestFutureAvailableEntry.length - 1]
          .startDate,
      );

      const diff = moment(latestApprovedDate).diff(
        moment(new Date()).format("YYYY-MM-DD"),
        "days",
      );

      if (diff >= Number(allowFromDays)) {
        selectedDate = dayjs(
          moment(latestApprovedDate).add(1, "days").toDate(),
        );
      } else if (diff < Number(allowFromDays)) {
        selectedDate = dayjs(
          today.utc().add(Number(allowFromDays), "days").toDate(),
        );
      }
    }
    return selectedDate;
  };

  useEffect(() => {
    const nextDays = [];
    const today = moment(new Date());

    if (futureAvailabilities.length === 0) {
      for (let i = 0; i < Number(allowFromDays); i++) {
        nextDays.push(moment(today).add(i, "days").toDate());
      }
      if (startDate) {
        setSelectedDate(
          dayjs(today.add(Number(allowFromDays), "days").toDate()),
        );
      }
    } else {
      const latestApprovedDate = moment(
        latestFutureAvailableEntry[latestFutureAvailableEntry.length - 1]
          .startDate,
      );

      const diff = moment(latestApprovedDate).diff(
        moment(new Date()).format("YYYY-MM-DD"),
        "days",
      );
      if (diff >= Number(allowFromDays)) {
        for (let i = 0; i <= diff; i++) {
          nextDays.push(moment(today).add(i, "days").toDate());
        }
        if (startDate) {
          setSelectedDate(dayjs(latestApprovedDate.add(1, "days").toDate()));
        }
      } else if (diff < Number(allowFromDays)) {
        for (let i = 0; i < Number(allowFromDays); i++) {
          nextDays.push(moment(today).add(i, "days").toDate());
        }
        if (startDate) {
          setSelectedDate(
            dayjs(today.add(Number(allowFromDays), "days").toDate()),
          );
        }
      }
    }
    setStrikeDays(nextDays);
  }, [startDate, showStartDateCalendar]);

  const shouldDisableEndDate = (date: Date | null) => {
    if (!date || !startDate) return false;
    const selectedDate = dayjs(date)?.startOf("day");
    const endDate = dayjs(startDate)?.add(1, "year").endOf("day");
    return (
      selectedDate.isBefore(startDate, "day") ||
      selectedDate.isSame(startDate, "day") ||
      dayjs(date)?.isAfter(endDate, "day")
    );
  };

  const shouldDisableStartDateCustom = (startDate?: Date | null) => {
    const selectedDate = dayjs(startDate)?.startOf("day");
    const today = dayjs().startOf("day");
    let startDateBeforeApproved = false;
    const allowDate = moment(today.toDate())
      .add(Number(allowFromDays), "days")
      .startOf("day");

    if (futureAvailabilities.length !== 0) {
      const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
      const latestApprovedDate = moment(
        latestFutureAvailableEntry[latestFutureAvailableEntry.length - 1]
          .startDate,
      );
      if (
        moment(formattedStartDate).isSameOrBefore(latestApprovedDate) &&
        moment(formattedStartDate).isSameOrAfter(moment(allowDate))
      ) {
        startDateBeforeApproved = true;
      }
      const result =
        selectedDate.isBefore(today, "day") ||
        selectedDate.diff(today, "day") < Number(allowFromDays) ||
        startDateBeforeApproved;
      return result;
    } else {
      const result =
        selectedDate.isBefore(today, "day") ||
        selectedDate.diff(today, "day") < Number(allowFromDays);
      return result;
    }
  };

  const returnApprovedRequestResult = (startDate?: Date | null) => {
    if (startDate !== undefined) {
      let result = false;
      const today = moment(new Date());
      const allowedDate = moment(today)
        .add(Number(allowFromDays), "days")
        .startOf("day");

      if (futureAvailabilities.length !== 0) {
        const latestApprovedDate = moment(
          latestFutureAvailableEntry[latestFutureAvailableEntry.length - 1]
            .startDate,
        );
        const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
        result =
          moment(formattedStartDate).isSameOrBefore(latestApprovedDate) &&
          moment(formattedStartDate).isSameOrAfter(moment(allowedDate));

        setApprovedStartDateExists(result);
        return result;
      }
    }
  };

  useEffect(() => {
    if (showStartDateCalendar || showEndDateCalendar) {
      let newSelectedDate = null;
      let newEndSelectedDate = null;

      if (showStartDateCalendar && !startDate) {
        newSelectedDate = initialSelectionComplete
          ? null
          : returnInitialSelectDate();
      } else if (startDate) {
        newSelectedDate = dayjs(startDate);
      }

      if (showEndDateCalendar && !endDate && startDate) {
        newEndSelectedDate =
          initialSelectionEndComplete && !newSelectedDate
            ? null
            : dayjs(dayjs(startDate).add(1, "days").toDate());
      } else if (endDate) {
        newEndSelectedDate = dayjs(endDate);
      }
      if (newSelectedDate) setSelectedDate(newSelectedDate);
      setEndSelectedDate(newEndSelectedDate);

      if (newSelectedDate !== null && !initialSelectionComplete) {
        setInitialSelectionComplete(true);
      }

      if (newEndSelectedDate !== null && !initialSelectionEndComplete) {
        setInitialSelectionEndComplete(true);
      }
    }
  }, [
    showStartDateCalendar,
    showEndDateCalendar,
    startDate,
    endDate,
    initialSelectionComplete,
    initialSelectionEndComplete,
    endDateCleared,
  ]);

  const handleStartDate = (date: Date | null) => {
    if (date) {
      setEndDateCleared(true);
      onSetEndDate(null);
      const formattedStartDate = dayjs(date)?.format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ",
      );
      if (date) {
        onSetStartDate(formattedStartDate);
        setEndDateCleared(false);
      }
      setSelectedStartDate(formattedStartDate);
      if (date && shouldDisableStartDateCustom(date as Date | null)) {
        onStartDateDisabled(true);
      } else {
        onStartDateDisabled(false);
        propHandleStartDate(date);
        setShowStartDateCalendar(false);
      }
      const existRequestData = availabilityRequestData?.availabilityRequests;
      const pendingRequestData = existRequestData?.filter(
        (request: { status: string }) => request.status === PENDING,
      );
      if (formattedStartDate) {
        let isStartAndNullEndDateExist = false;
        if (pendingRequestData?.length > 0) {
          pendingRequestData?.forEach((pendingData: any) => {
            const formattedStartDateUTC =
              dayjs(formattedStartDate)?.format("YYYY-MM-DD");
            const effectiveFromUTC = moment
              .utc(pendingData?.effectiveFrom)
              ?.format("YYYY-MM-DD");
            const effectivePendingId = pendingData.id;
            const editPendingId = editPendingAvailabilityData?.id;
            if (editPendingId && editPendingAvailability) {
              if (
                (!editPendingAvailability &&
                  effectiveFromUTC === formattedStartDateUTC) ||
                (effectiveFromUTC !== formattedStartDateUTC &&
                  !editPendingAvailability &&
                  editPendingId !== effectivePendingId) ||
                (editPendingAvailability &&
                  editPendingId !== effectivePendingId &&
                  effectiveFromUTC === formattedStartDateUTC)
              ) {
                isStartAndNullEndDateExist = true;
                setShowStartDateCalendar(true);
              }
            } else {
              if (effectiveFromUTC === formattedStartDateUTC) {
                isStartAndNullEndDateExist = true;
                setShowStartDateCalendar(true);
              }
            }
          });
        }
        setIsStartAndNullEndDateExist(isStartAndNullEndDateExist);
      }
    } else {
      onStartDateDisabled(true);
    }
  };

  const handleEndDate = (date: Date | null) => {
    if (date) {
      const formattedEndDate = dayjs(date)?.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      onSetEndDate(formattedEndDate);
      const today = dayjs()?.startOf("day");
      const oneYearLater =
        selectedStartDate || startDate
          ? dayjs(selectedStartDate || startDate)
              .add(1, "year")
              .endOf("day")
          : null;

      if (
        selectedStartDate.split("T")[0] === formattedEndDate.split("T")[0] ||
        dayjs(startDate)?.format("YYYY-MM-DD") ===
          formattedEndDate.split("T")[0]
      ) {
        onEndDateDisabled(true);
      } else if (
        dayjs(formattedEndDate).isAfter(oneYearLater) ||
        (!startDate && dayjs(formattedEndDate).isAfter(today)) ||
        dayjs(formattedEndDate).isSame(today) ||
        dayjs(formattedEndDate).isBefore(today) ||
        dayjs(formattedEndDate).isBefore(selectedStartDate)
      ) {
        onEndDateDisabled(true);
      } else {
        onEndDateDisabled(false);
        propHandleEndDate(date);
        setShowEndDateCalendar(false);
      }

      const existRequestData = availabilityRequestData?.availabilityRequests;
      const pendingRequestData = existRequestData?.filter(
        (request: { status: string }) => request.status === PENDING,
      );
      if ((startDate && endDate) ?? (startDate && formattedEndDate)) {
        let isStartAndNullEndDateExist = false;
        const editPendingId = editPendingAvailabilityData?.id;
        if (pendingRequestData?.length > 0) {
          pendingRequestData?.forEach((pendingData: any) => {
            const effectivePendingId = pendingData.id;
            if (!editPendingId && !editPendingAvailability) {
              if (
                (dayjs(pendingData?.effectiveFrom)?.format("YYYY-MM-DD") ===
                  dayjs(startDate)?.format("YYYY-MM-DD") &&
                  dayjs(pendingData?.endsAfter)?.format("YYYY-MM-DD") ===
                    dayjs(formattedEndDate)?.format("YYYY-MM-DD")) ||
                dayjs(pendingData?.effectiveFrom)?.format("YYYY-MM-DD") ===
                  dayjs(startDate)?.format("YYYY-MM-DD")
              ) {
                isStartAndNullEndDateExist = true;
                setShowStartDateCalendar(true);
              }
            } else {
              if (
                (effectivePendingId !== editPendingId &&
                  dayjs(pendingData?.effectiveFrom)?.format("YYYY-MM-DD") ===
                    dayjs(startDate)?.format("YYYY-MM-DD") &&
                  dayjs(pendingData?.endsAfter)?.format("YYYY-MM-DD") ===
                    dayjs(formattedEndDate)?.format("YYYY-MM-DD")) ||
                (effectivePendingId !== editPendingId &&
                  dayjs(pendingData?.effectiveFrom)?.format("YYYY-MM-DD") ===
                    dayjs(startDate)?.format("YYYY-MM-DD"))
              ) {
                isStartAndNullEndDateExist = true;
                setShowStartDateCalendar(true);
              }
            }
          });
        }
        setIsStartAndNullEndDateExist(isStartAndNullEndDateExist);
      } else {
        setIsStartAndNullEndDateExist(false);
      }
    } else {
      onEndDateDisabled(true);
    }
  };

  const stuckDays = (props: PickersDayProps<Date>) => {
    const strikeClass = strikeDays.reduce((curr, acc) => {
      const date = new Date(props.day);
      return moment(date).isSame(moment(acc), "D") ? "strikeDays" : curr;
    }, "");

    const dateValue = new Date(props.day)?.getDate();
    return (
      <PickersDay {...props} className={strikeClass}>
        {strikeClass.length !== 0 && <span>{dateValue}</span>}
      </PickersDay>
    );
  };

  const isNextDay = (date1: Date, date2: Date) => {
    const nextDay = new Date(date1);
    nextDay?.setDate(nextDay?.getDate() + 1);
    return nextDay?.toDateString() === date2?.toDateString();
  };

  const greenGroupDays = (props: PickersDayProps<Date>) => {
    const greenDays = hightLightDays?.reduce((curr, acc, index) => {
      const date = new Date(props.day);
      let elClass;
      if (
        index === hightLightDays?.length - 1 &&
        hightLightDays?.length === 2 &&
        isNextDay(hightLightDays[0], hightLightDays[1])
      ) {
        elClass = "before-last";
      } else if (index === hightLightDays?.length - 1) {
        elClass = "last";
      } else if (
        index === hightLightDays?.length - 2 &&
        !(
          hightLightDays?.length === 2 &&
          isNextDay(hightLightDays[0], hightLightDays[1])
        )
      ) {
        elClass = "second-last";
      } else {
        elClass = String(index);
      }
      return moment(date).isSame(acc) ? `green-day-${elClass}` : curr;
    }, {});
    return (
      <PickersDay
        {...props}
        className={
          Object.getOwnPropertyNames(greenDays)?.length === 0
            ? ""
            : "green-days"
        }
      />
    );
  };

  const handleMonthChange = () => {
    if (showYearCalendar || !showYearCalendar) {
      setShowYearCalendar((prevShowYearCalendar: any) => !prevShowYearCalendar);
    }
  };

  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    if (date === undefined) return;
    const result =
      shouldDisableStartDateCustom(date as Date | null) &&
      returnApprovedRequestResult(date as Date | null);
    if (result) {
      setSelectedDate(null);
      handleStartDate(null);
    } else {
      handleStartDate(date as Date | null);
      setSelectedDate(date);
    }
  };

  const handleYearChange = (date: dayjs.Dayjs | null) => {
    if (dayjs(date)?.diff(dayjs(), "day") < 365) {
      handleStartDate(date as Date | null);
      setSelectedDate(date);
    }
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    const selectedEndDate = dayjs(date)?.startOf("day");
    const endDateForOneYear = dayjs(startDate)?.add(1, "year").endOf("day");
    const startDateUTC = dayjs(startDate).startOf("day");
    if (
      !startDateUTC ||
      selectedEndDate.isBefore(startDateUTC) ||
      selectedEndDate.isAfter(endDateForOneYear)
    ) {
      handleEndDate(null);
      setEndSelectedDate(null);
    } else {
      handleEndDate(date as Date | null);
      setEndSelectedDate(date);
    }
  };

  useEffect(() => {
    if (!showStartDateCalendar || !showEndDateCalendar) {
      setShowYearCalendar(false);
    }
  }, [showStartDateCalendar, showEndDateCalendar]);

  useEffect(() => {
    if (endDateCleared === true && showEndDateCalendar === true) {
      setEndDateCleared(false);
      setShowEndDateCalendar(false);
    }
  }, [endDateCleared, showEndDateCalendar]);

  setTimeout(() => {
    const buttons = document?.querySelectorAll(
      ".MuiIconButton-edgeStart, .MuiIconButton-edgeEnd",
    );
    buttons?.forEach((button) => {
      button?.removeAttribute("title");
    });
  }, 100);

  return (
    <>
      <Grid xs={12} container item justifyContent={"space-between"}>
        <Stack
          className={
            showStartDateCalendar
              ? "date-wrapper half-width active"
              : startDate
                ? "date-wrapper half-width startDateBorder"
                : "date-wrapper half-width"
          }
          tabIndex={0}
          role="button"
          aria-label={translate("CHOOSE_START_DATE")}
          onClick={() => {
            analytics?.trackEvent({
              name: `End date calendar closed`,
              properties: {
                page: "Availability",
                workflow: "Create",
                component: "DatePick",
              },
            });
            setShowEndDateCalendar(false);
            setShowStartDateCalendar(!showStartDateCalendar);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              analytics?.trackEvent({
                name: `End date calendar closed`,
                properties: {
                  page: "Availability",
                  workflow: "Create",
                  component: "DatePick",
                },
              });
              setShowEndDateCalendar(false);
              setShowStartDateCalendar(!showStartDateCalendar);
            }
          }}
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"}>
            <TypoGraphy typeClass="light-font mr-half" variant="body1">
              {translate("START")}
            </TypoGraphy>
            <TypoGraphy variant="body1" typeClass="semi-bold-font">
              {startDate &&
                dayjs(startDate)?.format(
                  i18n.language === "fr" ? "YYYY-MM-DD" : "MMM DD, YYYY",
                )}
            </TypoGraphy>
          </Stack>
          <Stack textAlign={"right"}>
            <img
              id="calendarMonthIcon"
              src={CalendarIconEnabled}
              alt={"calendar"}
              className="warning-icon"
            />
          </Stack>
        </Stack>
        <Stack
          className={
            showEndDateCalendar && startDate
              ? "date-wrapper half-width active"
              : endDate
                ? "date-wrapper half-width startDateBorder"
                : "date-wrapper half-width"
          }
          tabIndex={0}
          aria-label={translate("CHOOSE_END_DATE")}
          role="button"
          onClick={() => {
            analytics?.trackEvent({
              name: `End date calendar closed`,
              properties: {
                page: "Availability",
                workflow: "Create",
                component: "DatePick",
              },
            });
            setShowEndDateCalendar(!showEndDateCalendar);
            setShowStartDateCalendar(false);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              analytics?.trackEvent({
                name: `End date calendar closed`,
                properties: {
                  page: "Availability",
                  workflow: "Create",
                  component: "DatePick",
                },
              });
              setShowEndDateCalendar(!showEndDateCalendar);
              setShowStartDateCalendar(false);
            }
          }}
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"}>
            <TypoGraphy typeClass="light-font mr-half" variant="body1">
              {endDate
                ? translate("END")
                : `${translate("END")} (${translate("OPTIONAL")})`}
            </TypoGraphy>
            <TypoGraphy variant="body1" typeClass="semi-bold-font">
              {endDate &&
                dayjs(endDate)?.format(
                  i18n.language === "fr" ? "YYYY-MM-DD" : "MMM DD, YYYY",
                )}
            </TypoGraphy>
          </Stack>
          <Stack direction={"row"} textAlign={"right"}>
            {startDate ? (
              <img
                id="calendarMonthIcon"
                src={CalendarIconEnabled}
                alt={"calendar"}
                className="warning-icon"
              />
            ) : (
              <img
                id="calendarMonthIconDisabled"
                src={CalendarIconDisabled}
                alt={"calendar"}
                className="warning-icon"
              />
            )}
            {endDate && (
              <IconButton
                onClick={() => {
                  setEndDateCleared(true);
                  onSetEndDate(null);
                  analytics?.trackEvent({
                    name: `Optional End Date removed`,
                    properties: {
                      page: "Availability",
                      workflow: "Create",
                      component: "DatePick",
                    },
                  });
                }}
                aria-label={translate("CLEAR_END_DATE")}
                tabIndex={0}
                role={BUTTON}
                className="clear-date"
              >
                <Close />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Grid>
      {showStartDateCalendar && !showEndDateCalendar && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack
            className={
              isStartAndNullEndDateExist
                ? "date-calendar left-cal hasError2"
                : shouldDisableStartDateCustom(startDate)
                  ? "date-calendar left-cal hasError"
                  : "date-calendar left-cal"
            }
          >
            <DateCalendar
              disablePast={true}
              onChange={handleStartDateChange}
              dayOfWeekFormatter={(_day, weekday) =>
                dayjs(weekday)
                  .locale(i18n.language)
                  .format("ddd")
                  .toUpperCase()
                  .replace(/\./g, "")
              }
              shouldDisableDate={(date) => shouldDisableStartDate(dayjs(date))}
              shouldDisableMonth={(month) =>
                shouldDisableStartDateCalendarMonth(dayjs(month))
              }
              views={["day", "month"]}
              slots={{ day: stuckDays }}
              onViewChange={handleMonthChange}
              value={
                dayjs(selectedDate).diff(dayjs(), "year") > 1
                  ? null
                  : (selectedDate as Date | null)
              }
            />
            {showYearCalendar && (
              <YearCalendar
                minDate={dayjs().startOf("day")}
                maxDate={dayjs().add(2, "year")}
                onChange={
                  dayjs(selectedDate)?.diff(dayjs(), "day") > 365
                    ? handleStartDateChange
                    : handleYearChange
                }
                value={selectedDate}
              />
            )}
            {approvedStartDateExists && (
              <WarningTip message={translate("SELECTED_DATE_UNAVAILABLE")} />
            )}
            {shouldDisableStartDateCustom(startDate) &&
              !approvedStartDateExists && (
                <WarningTip
                  message={translate("START_DATE_21_DAYS_IN_THE_FUTURE")}
                />
              )}
            {isStartAndNullEndDateExist && !approvedStartDateExists && (
              <WarningTip message={translate("REQUEST_OVERLAP")} />
            )}
          </Stack>
        </LocalizationProvider>
      )}

      {showEndDateCalendar &&
        !showStartDateCalendar &&
        !endDateCleared &&
        startDate && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
              className={
                isStartAndNullEndDateExist
                  ? "date-calendar right-cal-1 hasError2"
                  : "date-calendar right-cal-1"
              }
            >
              <DateCalendar
                disablePast={true}
                onChange={handleEndDateChange}
                dayOfWeekFormatter={(_day, weekday) =>
                  dayjs(weekday)
                    .locale(i18n.language)
                    .format("ddd")
                    .toUpperCase()
                    .replace(/\./g, "")
                }
                shouldDisableDate={(date: Date | null) =>
                  shouldDisableEndDate(date)
                }
                shouldDisableMonth={(month) =>
                  shouldDisableEndDateCalendarMonth(dayjs(month))
                }
                disabled={isStartAndNullEndDateExist}
                slots={{ day: greenGroupDays }}
                views={["day", "month"]}
                onViewChange={handleMonthChange}
                value={endSelectedDate as Date | null}
              />
              {showYearCalendar && (
                <YearCalendar
                  minDate={dayjs().startOf("day")}
                  maxDate={dayjs().add(2, "year")}
                  onChange={handleEndDateChange}
                  value={selectedDate}
                />
              )}
              {isStartAndNullEndDateExist && (
                <WarningTip message={translate("REQUEST_OVERLAP")} />
              )}
            </Stack>
          </LocalizationProvider>
        )}
    </>
  );
};

export default DatePick;
