import {
  Typography,
  Button,
  Grid,
  Stack,
  MenuItem,
  Select,
  Paper,
  Divider,
} from "@mui/material";
import "./style.scss";
import "../../../../../styles/drawer.scss";
import { useTranslation } from "react-i18next";
import {
  setIdealShifts,
  setPreferredShifts,
} from "../../../../../features/metaReducer";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import CustomBtn from "../../../../common/Button";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import {
  DEFAULT_TIME,
  END_TIME_OPTIONS,
  LAST_SELECTABLE_END_TIME,
  LAST_SELECTABLE_START_TIME,
  START_TIME_OPTIONS,
} from "../Constant";
import CustomIconBtn from "../../../../common/IconBtn";
import {
  add15Minutes,
  addMinutes,
  formatTime,
  formatTimeString,
  getMinutes,
  WeeklyDay,
} from "../../../../../utils/availabilityUpdate";
import { useContext, useEffect, useState } from "react";
import { convertTo24Hour } from "../../../../../utils/durationCalculate";
import {
  END_DATE_INCLUDE_DOT_1_Value,
  smallDesktop,
} from "../../../../GlobalConstants";
import { AnalyticsContext } from "../../../../../analytics";
import useViewportWidth from "../../../../../utils/useViewportWidth";
import moment from "moment";

interface Props {
  weeklyDays: WeeklyDay[];
}

const IdealShifts: React.FC<Props> = ({ weeklyDays }) => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const startTimeArr: string[] = START_TIME_OPTIONS;
  const endTimeArr: string[] = END_TIME_OPTIONS;
  const [times, setTimes] = useState<any>([]);
  const [staticTimeSlotIS, setStaticTimeSlotIS] = useState<
    { startTime: string; endTime: string }[]
  >([]);
  const [generalAvailabilityTimings, setGeneralAvailabilityTimings] =
    useState<any>();

  const { weekDayIdealShift: dayName, preferredShifts } = useAppSelector(
    (state: any) => state.meta,
  );

  const windowWidth = useViewportWidth();
  const analytics = useContext(AnalyticsContext);

  useEffect(() => {
    weeklyDays.map((item) => {
      if (item.dayOfWeek === dayName) {
        setGeneralAvailabilityTimings(item.generalAvailabilityTimings);
      }
    });
  }, []);

  /**
   * Set Preferred Shifts to a Global state in a format which gives all ideal shifts accumulated for a day irrespective of the general availability timings for a day.
   *
   * @param {[key] : [{startTime: string; endTime: string}]} times
   */
  const setPreferredShiftsFunc = (
    times: { startTime: string; endTime: string }[][],
  ) => {
    const innerObj: any = {};
    if (preferredShifts) {
      Object.assign(innerObj, preferredShifts);
    } else {
      Object.assign(innerObj, {});
    }
    // When we have multiple shifts for a General Availability slot
    if (times?.length > 1) {
      const arr: { startTime: string; endTime: string }[] = [];
      times.map((item: any, parentIndex: any) => {
        arr.push(...item[parentIndex]);
      });
      innerObj[`${dayName}`] = { ...arr };
      // When we have single shift for a General Availability slot
    } else {
      times.map(
        (
          item: { startTime: string; endTime: string }[],
          parentIndex: number,
        ) => {
          innerObj[`${dayName}`] = { ...item[parentIndex] };
        },
      );
    }
    dispatch(setPreferredShifts(innerObj));
  };

  /**
   * Get the disabled slots for times where add more icon is clicked and the previous slots with selected values are disabled.
   *
   * @param {number} parentIndex
   * @param {number} index
   * @returns {*}
   */
  const getDisabledSlots = (parentIndex: number, index: number) => {
    if (times[parentIndex][parentIndex].length > 1) {
      const arrayDisabledSlots = Array.from(
        { length: times[parentIndex][parentIndex].length - 1 },
        (_, index) => index,
      );
      return arrayDisabledSlots?.includes(index);
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (generalAvailabilityTimings) {
      // *GA = General Availability
      // *IS = Ideal Shift
      // First we are checking that GA is only one slot which can be 12 AM to 12 AM as well and there are no IS.
      if (
        generalAvailabilityTimings.length === 1 &&
        generalAvailabilityTimings[0]?.preferredAvailabilityTimings.length === 0
      ) {
        if (generalAvailabilityTimings[0]?.allDayAvailable === true) {
          times.push({
            0: [
              {
                startTime: "12:00 AM",
                endTime: "12:00 AM",
                selectedOnce: false,
              },
            ],
          });
          staticTimeSlotIS.push({
            startTime: "12:00 AM",
            endTime: "12:00 AM",
          });
        } else {
          times.push({
            0: [
              {
                startTime: generalAvailabilityTimings[0].startTimeOffset,
                endTime: generalAvailabilityTimings[0].endTimeOffset,
                selectedOnce: false,
              },
            ],
          });
          staticTimeSlotIS.push({
            startTime: generalAvailabilityTimings[0].startTimeOffset,
            endTime: generalAvailabilityTimings[0].endTimeOffset,
          });
        }
      }
      // Now, we are checking that GA is only one slot which can be 12 AM to 12 AM as well and there are IS available for that slot which can be more than one IS slots.
      else if (
        generalAvailabilityTimings.length === 1 &&
        generalAvailabilityTimings[0]?.preferredAvailabilityTimings.length !== 0
      ) {
        const arr = [];
        for (const item of generalAvailabilityTimings[0]
          .preferredAvailabilityTimings) {
          arr.push({
            startTime: `${moment(new Date(`01/01/2025 ${item.startTimeOffset}`)).format("h:mm A")}`,
            endTime: `${moment(new Date(`01/01/2025 ${item.endTimeOffset}`)).format("h:mm A")}`,
            selectedOnce: true,
          });
        }
        times.push({
          0: [...arr],
        });
        staticTimeSlotIS.push({
          startTime:
            generalAvailabilityTimings[0]?.allDayAvailable === true
              ? "12:00 AM"
              : generalAvailabilityTimings[0].startTimeOffset,
          endTime:
            generalAvailabilityTimings[0]?.allDayAvailable === true
              ? "12:00 AM"
              : generalAvailabilityTimings[0].endTimeOffset,
        });
      }
      // Now, we are checking that GA is more than one slot which and there are no IS available for that slot.
      else if (
        generalAvailabilityTimings.length > 1 &&
        generalAvailabilityTimings[0]?.preferredAvailabilityTimings.length === 0
      ) {
        for (let item = 0; item < generalAvailabilityTimings.length; item++) {
          times.push({
            [item]: [
              {
                startTime: generalAvailabilityTimings[item].startTimeOffset,
                endTime: generalAvailabilityTimings[item].endTimeOffset,
                selectedOnce: false,
              },
            ],
          });
          staticTimeSlotIS.push({
            startTime: generalAvailabilityTimings[item].startTimeOffset,
            endTime: generalAvailabilityTimings[item].endTimeOffset,
          });
        }
      } else {
        //Here, finally if none of the above conditions satisfy we are checking if GA is more than one and there are more than one or one IS slot or null in any/all of the GA slots, then we add the IS as per the times structure to populate on UI.
        if (generalAvailabilityTimings.length > 1) {
          for (let item = 0; item < generalAvailabilityTimings.length; item++) {
            if (
              generalAvailabilityTimings[item]?.preferredAvailabilityTimings
                .length !== 0
            ) {
              const parentArr: any[] = [];
              let childArr: any[] = [];
              for (const shiftItem of generalAvailabilityTimings[item]
                .preferredAvailabilityTimings) {
                childArr = [];
                childArr.push({
                  startTime: `${moment(new Date(`01/01/2025 ${shiftItem.startTimeOffset}`)).format("h:mm A")}`,
                  endTime: `${moment(new Date(`01/01/2025 ${shiftItem.endTimeOffset}`)).format("h:mm A")}`,
                  selectedOnce: true,
                });
                parentArr.push({
                  [item]: [...childArr],
                });
              }
              staticTimeSlotIS.push({
                startTime: generalAvailabilityTimings[item].startTimeOffset,
                endTime: generalAvailabilityTimings[item].endTimeOffset,
              });
              times.push(...parentArr);
            }
          }
        }
      }
      setTimes(Array.from(new Set(times)));
      setStaticTimeSlotIS(Array.from(new Set(staticTimeSlotIS)));
    }
  }, [generalAvailabilityTimings]);

  /**
   * handleStartTime
   * Method handles the start time dropdown selection. This method considers the parentIndex of the generalAvailability coming from create view.
   * @param {string} item Selected dropdown value
   * @param {number} parentIndex Index for the general Availability value
   * @param {number} index Current Dropdown row index
   * @returns {void}
   */
  const handleStartTime =
    (item: string, parentIndex: number, index: number) => () => {
      const startTime = item;

      setTimes((prevTimes: any) => {
        /* The previous times structure -- times/previousTimes is an array of object which has a key value structure where the key is the index of the general availability which stores an array of ideal shifts time slots and info  if this has been selected once to support add time functionality.
         */
        const newTimes = [...prevTimes];
        const newTimesArrOBj = newTimes[parentIndex][parentIndex][index];
        newTimesArrOBj.startTime = startTime;
        newTimesArrOBj.endTime = add15Minutes(startTime);
        newTimesArrOBj.selectedOnce = true;
        return newTimes;
      });
    };

  /**
   * handleEndTime
   * Method handles the end time dropdown selection. This method considers the parentIndex of the generalAvailability coming from create view.
   * @param {string} item Selected dropdown value
   * @param {number} parentIndex Index for the general Availability value
   * @param {number} index Current Dropdown row index
   * @returns {void}
   */
  const handleEndTime =
    (item: string, parentIndex: number, index: number) => () => {
      const endTime = item;

      if (
        !(times[parentIndex][parentIndex][index].startTime === DEFAULT_TIME)
      ) {
        setTimes((prevTimes: any) => {
          const newTimes = [...prevTimes];
          const newTimesArrOBj = newTimes[parentIndex][parentIndex][index];
          newTimesArrOBj.endTime = endTime;
          const startTime24 = convertTo24Hour(newTimesArrOBj?.startTime);
          const endTime24 =
            endTime === END_DATE_INCLUDE_DOT_1_Value
              ? "24:00"
              : convertTo24Hour(endTime);
          const endTime24Arr = endTime24.split(":").map(Number);
          const startTimeArr = startTime24.split(":").map(Number);
          if (
            endTime24 < startTime24 ||
            (endTime > startTime24 &&
              endTime24Arr[0] === 0 &&
              startTimeArr[0] === 0 &&
              endTime24Arr[1] === 0 &&
              startTimeArr[0] !== 0) ||
            endTime24 === startTime24
          ) {
            newTimesArrOBj.endTime = addMinutes(newTimesArrOBj?.startTime, 15);
          }
          return newTimes;
        });
      } else {
        console.log("Please select a start time before selecting an end time.");
      }
    };

  /**
   * Add More Time Slot in Ideal Shifts
   *
   * @param {number} index
   */
  const addMoreTime = (index: number) => {
    const lastEntry = {
      //using only index ( staticTimeSlots index ) here since the function is out of scope fro times iteration. We are getting the last entry from the times[{[item] : {[startTime: string, endTime: string, selectedOnce: boolean]}}]
      startTime: times[index][index][times[index][index].length - 1].startTime,
      endTime: times[index][index][times[index][index].length - 1].endTime,
    };

    if (
      lastEntry.startTime !== DEFAULT_TIME &&
      lastEntry.endTime !== DEFAULT_TIME
    ) {
      if (times[index][index].length <= 4) {
        setTimes((prevTimes: any) => {
          const newTimes = [...prevTimes];
          newTimes[index][index].push({
            startTime: DEFAULT_TIME,
            endTime: DEFAULT_TIME,
            selectedOnce: false,
          });
          return newTimes;
        });
      }
    } else {
      console.log("Please select both start and end times before adding more.");
    }
  };

  /**
   * Remove time slot from ideal shifts
   *
   * @param {number} parentIndex
   * @param {number} index
   */
  const removeTime = (parentIndex: number, index: number) => {
    setTimes((prevTimes: any) => {
      const newTimes = [...prevTimes];
      newTimes[parentIndex][parentIndex]?.splice(index, 1);
      return newTimes;
    });
  };

  /**
   * Should the add more time icon be visible
   
   * @param {number} parentIndex
   * @param {*} generalAvailabilitySlot
   * @returns {boolean}
   */
  const isAddTimeVisible = (
    parentIndex: number,
    generalAvailabilitySlot: any,
  ) => {
    /* The add more icon should not be visible if the end time selected is the last time of general availability slot or end time for the store or 11:45 PM. 
    It should also not be visible when the start time is last selectable start time. 
    It should also not be visible when the end time slot is 15 minutes prior to end time of general availability slot as there will be no next selectable time. */
    return (
      times[parentIndex][parentIndex][
        times[parentIndex][parentIndex].length - 1
      ].startTime !== LAST_SELECTABLE_START_TIME &&
      times[parentIndex][parentIndex][
        times[parentIndex][parentIndex].length - 1
      ].endTime !== LAST_SELECTABLE_START_TIME &&
      times[parentIndex][parentIndex][
        times[parentIndex][parentIndex].length - 1
      ].endTime !== LAST_SELECTABLE_END_TIME &&
      times[parentIndex][parentIndex][
        times[parentIndex][parentIndex].length - 1
      ].endTime !== DEFAULT_TIME &&
      times[parentIndex][parentIndex].length - 1 !== -1 &&
      times[parentIndex][parentIndex][
        times[parentIndex][parentIndex].length - 1
      ].startTime !== DEFAULT_TIME &&
      times[parentIndex][parentIndex].length - 1 !== -1 &&
      formatTimeString(
        times[parentIndex][parentIndex][
          times[parentIndex][parentIndex].length - 1
        ].endTime,
      ) !== formatTimeString(generalAvailabilitySlot.endTime) &&
      addMinutes(
        times[parentIndex][parentIndex][
          times[parentIndex][parentIndex].length - 1
        ].endTime,
        15,
      ) !== formatTimeString(generalAvailabilitySlot.endTime)
    );
  };

  return (
    <Paper elevation={16} className={"paper-is"}>
      <>
        <Grid
          item
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          padding={"1rem"}
        >
          {windowWidth > smallDesktop ? (
            <div className={"titleBlock"}>
              <Typography id={"titleDesktop"}>
                {translate("SET_IDEAL_SHIFTS")}
              </Typography>
            </div>
          ) : (
            <>
              <Button
                onClick={() => {
                  dispatch(setIdealShifts(false));
                }}
                disabled={false}
                className={"submit-btn button-cancel-btn-mobile"}
              >
                {translate("CANCEL")}
              </Button>
              <CustomBtn
                text={translate("DONE")}
                className={"submit-btn create-submit-btn-mobile"}
                circularProgressClass="progress"
                onClick={() => {
                  setPreferredShiftsFunc(times);
                  dispatch(setIdealShifts(false));
                }}
              />{" "}
            </>
          )}
        </Grid>
        <div className="messageBox">
          <Typography id="message">
            {translate("SET_IDEAL_SHIFTS_INFO")}
          </Typography>
        </div>
        <div className={"ideal-shifts-container"}>
          {staticTimeSlotIS.map((generalAvailabilitySlot, parentIndex) => (
            <div className={"ideal-shifts-box"}>
              <Grid id="preferenceText">
                <Typography id="textA">{"Between"}</Typography>
                <Typography id="textB">{`
                ${moment(new Date(`01/01/2025 ${generalAvailabilitySlot.startTime}`)).format("h:mm A")}
                 to 
                  ${moment(new Date(`01/01/2025 ${generalAvailabilitySlot.endTime}`)).format("h:mm A")}
                 `}</Typography>
                <Typography id="textA">{"I prefer..."}</Typography>
              </Grid>

              {times[parentIndex][parentIndex]?.map(
                (element: any, index: any) => (
                  <div className={"ideal-shifts-box"}>
                    <Grid
                      container
                      direction={"row"}
                      className={"time-wrapper"}
                      alignItems={"center"}
                      key={index}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        className={"time-section"}
                      >
                        <Stack
                          id={
                            element.startTime === DEFAULT_TIME ||
                            element.selectedOnce === false
                              ? "timer-picker"
                              : "timer-picker-selected"
                          }
                        >
                          <Select
                            value={element.startTime}
                            renderValue={(selected) => {
                              if (element.selectedOnce === false) {
                                return translate("START_TIME");
                              } else {
                                if (element.selectedOnce === true) {
                                  return formatTime(selected);
                                }
                              }
                            }}
                            disabled={getDisabledSlots(parentIndex, index)}
                          >
                            {startTimeArr
                              .filter((startVal) => {
                                const startTime24Arr = parseInt(
                                  convertTo24Hour(startVal),
                                );
                                const startTimeMinutesArr = getMinutes(
                                  startVal,
                                  analytics,
                                );
                                const startTime24TimesShift = parseInt(
                                  convertTo24Hour(
                                    generalAvailabilitySlot?.startTime,
                                  ),
                                );
                                const startTimeMinutesTimesShift = getMinutes(
                                  generalAvailabilitySlot?.startTime,
                                  analytics,
                                );
                                const endTime24TimesShift = parseInt(
                                  convertTo24Hour(
                                    generalAvailabilitySlot?.endTime,
                                  ),
                                );
                                const endTimeMinutesTimesShift = getMinutes(
                                  generalAvailabilitySlot?.endTime,
                                  analytics,
                                );

                                if (index === 0) {
                                  if (
                                    generalAvailabilitySlot?.endTime ===
                                      "12:00 AM" &&
                                    generalAvailabilitySlot?.startTime ===
                                      "12:00 AM"
                                  ) {
                                    return true;
                                  } else if (
                                    generalAvailabilitySlot?.endTime ===
                                      "12:00 AM" &&
                                    generalAvailabilitySlot?.startTime !==
                                      "12:00 AM"
                                  ) {
                                    return (
                                      startTime24Arr * 60 +
                                        startTimeMinutesArr >=
                                      startTime24TimesShift * 60 +
                                        startTimeMinutesTimesShift
                                    );
                                  } else {
                                    return (
                                      startTime24Arr * 60 +
                                        startTimeMinutesArr >=
                                        startTime24TimesShift * 60 +
                                          startTimeMinutesTimesShift &&
                                      startTime24Arr * 60 +
                                        startTimeMinutesArr <
                                        endTime24TimesShift * 60 +
                                          endTimeMinutesTimesShift
                                    );
                                  }
                                } else if (index !== 0) {
                                  const endTime24TimesPrevious = parseInt(
                                    convertTo24Hour(
                                      times[parentIndex][parentIndex][index - 1]
                                        ?.endTime,
                                    ),
                                  );
                                  const endTimeMinutesTimesPrevious =
                                    getMinutes(
                                      times[parentIndex][parentIndex][index - 1]
                                        ?.endTime,
                                      analytics,
                                    );
                                  if (
                                    generalAvailabilitySlot?.endTime ===
                                    "12:00 AM"
                                  ) {
                                    return (
                                      startTime24Arr * 60 +
                                        startTimeMinutesArr >
                                      endTime24TimesPrevious * 60 +
                                        endTimeMinutesTimesPrevious
                                    );
                                  } else {
                                    return (
                                      startTime24Arr * 60 +
                                        startTimeMinutesArr >
                                        endTime24TimesPrevious * 60 +
                                          endTimeMinutesTimesPrevious &&
                                      startTime24Arr * 60 +
                                        startTimeMinutesArr <
                                        endTime24TimesShift * 60 +
                                          endTimeMinutesTimesShift
                                    );
                                  }
                                }
                              })
                              .map((displayTime) => (
                                <MenuItem
                                  onClick={handleStartTime(
                                    displayTime,
                                    parentIndex,
                                    index,
                                  )}
                                >
                                  {displayTime}
                                </MenuItem>
                              ))}
                          </Select>
                        </Stack>
                        <Stack className={"time-divider"}></Stack>
                        <Stack
                          id={
                            element.endTime === DEFAULT_TIME ||
                            element.selectedOnce === false
                              ? "timer-picker"
                              : "timer-picker-selected"
                          }
                        >
                          <Select
                            value={element.endTime}
                            renderValue={(selected) => {
                              if (element.selectedOnce === false) {
                                return translate("END_TIME");
                              } else {
                                if (element.selectedOnce === true) {
                                  return formatTime(selected);
                                }
                              }
                            }}
                            disabled={getDisabledSlots(parentIndex, index)}
                          >
                            {endTimeArr
                              .filter((endVal) => {
                                const endTime24Arr = parseInt(
                                  convertTo24Hour(endVal),
                                );
                                const endTimeMinutesArr = getMinutes(
                                  endVal,
                                  analytics,
                                );
                                const endTime24TimesShift = parseInt(
                                  convertTo24Hour(
                                    generalAvailabilitySlot?.endTime,
                                  ),
                                );
                                const endTimeMinutesTimesShift = getMinutes(
                                  generalAvailabilitySlot?.endTime,
                                  analytics,
                                );

                                const startTime24Times = parseInt(
                                  convertTo24Hour(element.startTime),
                                );
                                const startTimeMinutesTimes = getMinutes(
                                  element.startTime,
                                  analytics,
                                );
                                if (index === 0) {
                                  {
                                    if (
                                      generalAvailabilitySlot?.endTime ===
                                      "12:00 AM"
                                    ) {
                                      return (
                                        endTime24Arr * 60 + endTimeMinutesArr >
                                          startTime24Times * 60 +
                                            startTimeMinutesTimes ||
                                        endVal === "12:00 AM"
                                      );
                                    } else {
                                      return (
                                        endTime24Arr * 60 + endTimeMinutesArr >
                                          startTime24Times * 60 +
                                            startTimeMinutesTimes &&
                                        endTime24Arr * 60 + endTimeMinutesArr <=
                                          endTime24TimesShift * 60 +
                                            endTimeMinutesTimesShift
                                      );
                                    }
                                  }
                                } else if (index !== 0) {
                                  const endTime24TimesPreviousIndex = parseInt(
                                    convertTo24Hour(
                                      addMinutes(
                                        times[parentIndex][parentIndex][
                                          index - 1
                                        ].endTime,
                                        30,
                                      ),
                                    ),
                                  );
                                  const endTimeMinutesTimesPreviousIndex =
                                    getMinutes(
                                      addMinutes(
                                        times[parentIndex][parentIndex][
                                          index - 1
                                        ].endTime,
                                        30,
                                      ),
                                      analytics,
                                    );
                                  if (
                                    generalAvailabilitySlot?.endTime ===
                                    "12:00 AM"
                                  ) {
                                    return (
                                      (endTime24Arr * 60 + endTimeMinutesArr >=
                                        endTime24TimesPreviousIndex * 60 +
                                          endTimeMinutesTimesPreviousIndex &&
                                        endTime24Arr * 60 + endTimeMinutesArr >
                                          startTime24Times * 60 +
                                            startTimeMinutesTimes) ||
                                      endVal === "12:00 AM"
                                    );
                                  } else {
                                    return (
                                      endTime24Arr * 60 + endTimeMinutesArr >=
                                        endTime24TimesPreviousIndex * 60 +
                                          endTimeMinutesTimesPreviousIndex &&
                                      endTime24Arr * 60 + endTimeMinutesArr <=
                                        endTime24TimesShift * 60 +
                                          endTimeMinutesTimesShift &&
                                      endTime24Arr * 60 + endTimeMinutesArr >
                                        startTime24Times * 60 +
                                          startTimeMinutesTimes
                                    );
                                  }
                                }
                              })
                              .map((displayTime) => (
                                <MenuItem
                                  onClick={handleEndTime(
                                    displayTime,
                                    parentIndex,
                                    index,
                                  )}
                                >
                                  {displayTime}
                                </MenuItem>
                              ))}
                          </Select>
                        </Stack>
                      </Stack>
                      <Stack>
                        {index > 0 && (
                          <CustomIconBtn
                            IconComponent={RemoveCircleOutline}
                            iconClass={"remove-time"}
                            onClick={() => {
                              removeTime(parentIndex, index);
                            }}
                          />
                        )}
                      </Stack>
                    </Grid>
                  </div>
                ),
              )}
              {times[parentIndex][parentIndex].length < 5 && (
                <div className={"add-btn-block"}>
                  {isAddTimeVisible(parentIndex, generalAvailabilitySlot) && (
                    <CustomIconBtn
                      IconComponent={AddCircleOutline}
                      iconClass={"add-more-time"}
                      onClick={() => {
                        addMoreTime(parentIndex);
                      }}
                    />
                  )}
                </div>
              )}
              <Divider id={"divider-ideal-shifts"} />
            </div>
          ))}
        </div>
        {windowWidth > smallDesktop && (
          <div className={"buttonContainer"}>
            <Button
              fullWidth
              onClick={() => {
                dispatch(setIdealShifts(false));
              }}
              disabled={false}
              className={"submit-btn button-cancel-btn"}
            >
              {translate("CANCEL")}
            </Button>
            <CustomBtn
              fullWidth
              text={translate("DONE")}
              id={"create-submit-btn"}
              circularProgressClass="progress"
              onClick={() => {
                setPreferredShiftsFunc(times);
                dispatch(setIdealShifts(false));
              }}
            />
          </div>
        )}
      </>
    </Paper>
  );
};

export default IdealShifts;
