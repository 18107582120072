import { useState, useRef, useEffect, useContext } from "react";
import { Button, Grid, Modal, Typography } from "@mui/material";
import CustomDrawer from "../../../common/Drawer";
import DateHeader from "../../../shared/DateHeader/DateHeader";
import DatePick from "./DatePick";
import "../../../../styles/drawer.scss";
import CustomBtn from "../../../common/Button";
import AddRequest from "./WeeklyAvailabilityRequest";
import dayjs from "dayjs";
import useViewportWidth from "../../../../utils/useViewportWidth";
import { smallDesktop } from "../../../GlobalConstants";
import TypoGraphy from "../../../common/Typography";
import { defaultWeeklyDays } from "./Constant";
import SimpleBar from "simplebar-react";
import { PENDING } from "../../../GlobalConstants";
import { useTranslation } from "react-i18next";
import {
  makeAvailabilityRequest,
  updateAvailabilityRequest,
} from "../../../../features/availabilityRequestReducer/action";
import { setCopyCurrentAvailability } from "../../../../features/copyAvailabilityReducer";
import {
  updateUpdatedDays,
  editNewAvailabilityRequest,
} from "../../../../features/weekdaysReducer";
import {
  getPreferences,
  refreshPreference,
} from "../../../../features/preferencesReducer/action";
import Step from "./Step";
import Loader from "../../../shared/Loader/index";
import { getAvailabilityAll } from "../../../../features/availabilityAllReducer/action";
import {
  setEditPendingAvailability,
  setIdealShifts,
  setOnHomeScreen,
  setPreferredHoursFor150compliance,
  setPreferredShifts,
  setRetryAttemptAvailability,
} from "../../../../features/metaReducer";
import ConfirmDialog from "../../../common/ConfirmDialog";
import moment from "moment";
import ResultMessage from "../../../common/ResultMessage";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { getUserStoreHours } from "../../../../features/userStoreReducer/action";
import { AnalyticsContext, PageType } from "../../../../analytics";
import {
  returnAvailabilityToCancelPostEdit,
  WeeklyDay,
} from "../../../../utils/availabilityUpdate";
import IdealShifts from "./IdealShifts";
import RequestCondition from "./RequestCondition";
import AddComment from "./AddComment";
import "../../../../styles/drawer.scss";

interface Props {
  toggleDrawer: (open: boolean) => void;
}

const Create: React.FC<Props> = ({ toggleDrawer }) => {
  const [preferredHours, setPreferredHours] = useState<any>(0);
  const [requirementMeet, setRequirementMeet] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [futurePreferencesData, setFutureData] = useState<any>();
  const [currentPreferencesData, setCurrentPreferencesData] = useState<any>();
  const { t: translate } = useTranslation();
  const [nextStep, setNextStep] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [showStartDateCalendar, setShowStartDateCalendar] =
    useState<boolean>(false);
  const [showEndDateCalendar, setShowEndDateCalendar] =
    useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [totalHours, setTotalHours] = useState<any>("0:0");
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);
  const [isEditRequestSubmitSuccessful, setIsEditRequestSubmitSuccessful] =
    useState<boolean>(false);
  const [isRequestSubmitted, setRequestSubmitted] = useState<boolean>(false);
  const [isResubmitClicked, setIsResubmitClicked] = useState<boolean>(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
  const analytics = useContext(AnalyticsContext);

  useEffect(() => {
    analytics?.trackPageView({
      name: "Create Availability",
      pageType: PageType.NavigatePage,
    });
  }, []);

  const calculateTotalHours = (timeString: string | number): number => {
    if (typeof timeString === "number" || typeof timeString === "undefined") {
      return Number(timeString);
    } else {
      const [hours, minutes] = timeString.split(":").map(Number);
      return hours + (minutes || 0) / 60;
    }
  };

  const { availabilityRequestData } = useAppSelector(
    (state: any) => state.availabilityAll,
  );
  const { onHomeScreen, retryAttemptAvailability } = useAppSelector(
    (state: any) => state.meta,
  );
  const updatedWeekDaysStore = useAppSelector(
    (state) => state.updateWeekdays.updatedDays,
  );
  const editPendingAvailability = useAppSelector(
    (state: any) => state.meta.editPendingAvailability,
  );
  const {
    preferencesData,
    success: preferSuccess,
    error: preferenceError,
  } = useAppSelector((state: any) => state.preferences);

  const { storeData } = useAppSelector((state: any) => state?.userStoreData);
  const [weeklyDays, setWeeklyDays] = useState<WeeklyDay[]>([]);
  const [isStartDateDisabled, setIsStartDateDisabled] =
    useState<boolean>(false);
  const [isEndDateDisabled, setIsEndDateDisabled] = useState<boolean>(false);
  const [isStartAndNullEndDateExist, setIsStartAndNullEndDateExist] =
    useState<boolean>(false);
  const [isStartAndEndDateAlreadyExist, setIsStartAndEndDateAlreadyExist] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isPrefError, setIsPrefError] = useState<boolean>(false);
  const [maxResubmitAttemptsReached, setMaxResubmitAttemptsReached] =
    useState<boolean>(false);
  const windowWidth = useViewportWidth();
  const dispatch = useAppDispatch();
  const idCounterRef = useRef<number>(1);

  const allowFromDays = `${process.env.REACT_APP_REQUEST_ALLOW_FROM_DAYS}`;

  const allWeekDays = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  const editPendingAvailabilityData = useAppSelector(
    (state: any) => state.meta.editPendingAvailabilityData,
  );

  const isSetIdealShifts = useAppSelector(
    (state: any) => state.meta.isSetIdealShifts,
  );

  const editBtnDateHeaderFlag = useAppSelector(
    (state: any) => state.updateWeekdays.editNewAvailabilityRequest,
  );

  const adjustedTotalHours = totalHours;
  const totalHoursNumber = calculateTotalHours(adjustedTotalHours);

  useEffect(() => {
    const callPreferenceApi = async () => {
      dispatch(setOnHomeScreen(false));
      const empId = localStorage.getItem("userEmployeeId");
      if (preferSuccess === true || !empId || empId === "null") {
        return;
      } else {
        void (await dispatch(refreshPreference(false)));
        void (await dispatch(getPreferences()));
      }
    };
    void callPreferenceApi();
  }, []);

  useEffect(() => {
    setIsPrefError(preferenceError);
  }, [preferenceError]);

  const clearStateValue = () => {
    dispatch(setRetryAttemptAvailability(0));
    dispatch(setCopyCurrentAvailability(false));
    setWeeklyDays([]);
    setNextStep(false);
    setStartDate(null);
    setEndDate(null);
    setShowStartDateCalendar(false);
    setShowEndDateCalendar(false);
    setComment("");
    setIsStartDateDisabled(false);
    setIsEndDateDisabled(false);
    setIsStartAndNullEndDateExist(false);
    setTotalHours(0);
    setRequirementMeet(false);
    dispatch(updateUpdatedDays([]));
    dispatch(editNewAvailabilityRequest(false));
  };

  const handleStartDate = (date: any) => {
    if (date?.$d) {
      const formattedStartDate = dayjs(date)?.format("YYYY-MM-DD");
      setStartDate(formattedStartDate);
      const existRequestData = availabilityRequestData?.availabilityRequests;
      const pendingRequestData = existRequestData?.filter(
        (request: { status: string }) => request?.status === PENDING,
      );
      if (pendingRequestData && pendingRequestData?.length > 0) {
        let isExist = false;
        const formattedStartDateUTC =
          dayjs(formattedStartDate)?.format("YYYY-MM-DD");
        pendingRequestData?.forEach((pendingData: any) => {
          const effectiveFromUTC = moment
            .utc(pendingData?.effectiveFrom)
            ?.format("YYYY-MM-DD");
          if (effectiveFromUTC === formattedStartDateUTC) {
            isExist = true;
          }
        });
        if (editPendingAvailability === false) {
          setIsStartAndEndDateAlreadyExist(isExist);
        }
      } else {
        setIsStartAndEndDateAlreadyExist(false);
      }
    } else {
      analytics?.trackEvent({
        name: `Error: Invalid start date format`,
        properties: {
          page: "Availability",
          workflow: "Create",
          component: "Create",
        },
      });
      console.error("Invalid start date format");
    }
  };

  useEffect(() => {
    if (editPendingAvailability === true) {
      setStartDate(editPendingAvailabilityData.effectiveFrom);
      setEndDate(editPendingAvailabilityData.endsAfter);
      setComment(editPendingAvailabilityData.comments[0].comment);
      setWeeklyDays(editPendingAvailabilityData.availabilities);
    }
  }, [editPendingAvailability, editPendingAvailabilityData]);

  useEffect(() => {
    if (editPendingAvailability === true && editBtnDateHeaderFlag === false) {
      setNextStep(true);
    }
  }, [editPendingAvailability, nextStep, editNewAvailabilityRequest]);

  useEffect(() => {
    if (preferencesData && preferencesData.length !== 0) {
      const hoursArr: any = [];
      const futureHours: any = [];
      const userEnteredStart = dayjs(startDate).startOf("day");
      const userEnteredEnd =
        endDate !== null
          ? dayjs(endDate).startOf("day")
          : dayjs(new Date()).add(20, "years").startOf("day");

      if (preferencesData?.currentPreferredHours) {
        const startDate = preferencesData?.currentPreferredHours.start;
        const endDate = preferencesData?.currentPreferredHours.end;
        const recordStart = dayjs(startDate).startOf("day");
        const recordEnd =
          endDate !== null
            ? dayjs(endDate).startOf("day")
            : dayjs(new Date()).add(20, "years").startOf("day");

        if (
          (userEnteredStart >= recordStart && userEnteredStart < recordEnd) ||
          (userEnteredEnd > recordStart && userEnteredEnd <= recordEnd)
        ) {
          hoursArr.push(
            preferencesData?.currentPreferredHours.preferredHoursPerWeek,
          );
        }
      }

      if (preferencesData?.futurePreferredHours.length !== 0) {
        const futurePreferenceInRange =
          preferencesData.futurePreferredHours.filter((item: any) => {
            const recordStart = dayjs(item.start).startOf("day");
            const recordEnd =
              item.end !== null
                ? dayjs(item.end).startOf("day")
                : dayjs(new Date()).add(20, "years").startOf("day");
            return (
              (userEnteredStart >= recordStart &&
                userEnteredStart < recordEnd) ||
              (userEnteredEnd > recordStart && userEnteredEnd <= recordEnd) ||
              (userEnteredStart < recordStart && userEnteredEnd > recordEnd)
            );
          });

        if (futurePreferenceInRange) {
          futurePreferenceInRange.map((item: any) => {
            const val = item.preferredHoursPerWeek;
            futureHours.push(val);
          });
          const maximumVal = Math.max(...futureHours);
          hoursArr.push(maximumVal);
        }
      }
      const maxValue = Math.max(...hoursArr);
      setPreferredHours(maxValue);
      dispatch(setPreferredHoursFor150compliance(maxValue));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (preferencesData) {
      setFutureData(preferencesData?.futurePreferredHours);
      setCurrentPreferencesData(preferencesData?.currentPreferredHours);
    }
    if (preferredHours && preferredHours !== 0) {
      if (totalHours >= preferredHours * 1.5) {
        setRequirementMeet(true);
      } else {
        setRequirementMeet(false);
      }
    } else {
      if (totalHours < storeData.minAvailabilityHours) {
        setRequirementMeet(false);
      } else {
        setRequirementMeet(true);
      }
    }
  }, [totalHours, preferencesData]);

  const handleEndDate = (date: any) => {
    if (date?.$d) {
      const formattedEndDate = dayjs(date)?.format("YYYY-MM-DD");
      setEndDate(formattedEndDate);
      const existRequestData = availabilityRequestData?.availabilityRequests;
      const pendingRequestData = existRequestData?.filter(
        (request: { status: string }) => request?.status === PENDING,
      );
      if (pendingRequestData && pendingRequestData?.length > 0) {
        let isExist = false;
        pendingRequestData?.forEach((pendingData: any) => {
          if (
            moment.utc(pendingData?.effectiveFrom)?.format("YYYY-MM-DD") ===
            moment.utc(startDate)?.format("YYYY-MM-DD")
          ) {
            isExist = true;
          }
        });
        if (editPendingAvailability === false) {
          setIsStartAndEndDateAlreadyExist(isExist);
        }
      } else {
        setIsStartAndEndDateAlreadyExist(false);
      }
    } else {
      analytics?.trackEvent({
        name: `Error: Invalid end date format`,
        properties: {
          page: "Availability",
          workflow: "Create",
          component: "Create",
        },
      });
      console.error("Invalid end date format");
    }
  };

  const onNextBtn = (nextStep: any) => {
    // Fetch store hours for the selected start date week
    if (startDate) {
      setRequirementMeet(false);
      void dispatch(
        getUserStoreHours({
          date: dayjs(startDate).format("YYYY-MM-DD"),
          locationID: storeData.locationInfo.locationNbr,
        }),
      );
    }
    const selectedStartDateObj = dayjs(startDate)?.format("YYYY-MM-DD");

    const selectedEndDateObj =
      endDate !== null ? dayjs(endDate)?.format("YYYY-MM-DD") : endDate;

    const currentAvailabilityArray = [currentPreferencesData];

    const exactMatchData = currentAvailabilityArray?.find((item: any) => {
      const itemStartDate = dayjs(item?.start)?.format("YYYY-MM-DD");
      let itemEndDate = null;
      if (item?.end !== null) {
        itemEndDate = dayjs(item?.end)?.format("YYYY-MM-DD");
      } else {
        itemEndDate = null;
      }
      const bothStartEndDateSame = selectedStartDateObj == itemStartDate;
      const bothStartEndDatesSame =
        selectedStartDateObj == itemStartDate &&
        selectedEndDateObj == itemEndDate;
      const bothNullEndDates =
        selectedStartDateObj == itemStartDate &&
        (selectedEndDateObj == null) == (itemEndDate == null);

      return bothNullEndDates || bothStartEndDateSame || bothStartEndDatesSame;
    });

    if (!exactMatchData) {
      let rangeMatchData = currentAvailabilityArray?.find((item: any) => {
        const itemStartDate = dayjs(item?.start)?.format("YYYY-MM-DD");
        let itemEndDate = null;
        if (item?.end !== null) {
          itemEndDate = dayjs(item?.end)?.format("YYYY-MM-DD");
        } else {
          itemEndDate = null;
        }

        let startDateWithinRange = false;
        let endDateWithinRange = false;

        if (!exactMatchData) {
          startDateWithinRange =
            selectedStartDateObj >= itemStartDate &&
            (!itemEndDate || itemEndDate >= selectedStartDateObj);
          endDateWithinRange =
            selectedEndDateObj &&
            itemEndDate &&
            selectedEndDateObj <= itemEndDate &&
            selectedStartDateObj <= itemEndDate;
        }
        return startDateWithinRange || endDateWithinRange;
      });

      if (!rangeMatchData) {
        rangeMatchData = futurePreferencesData?.find((item: any) => {
          const itemStartDate = dayjs(item?.start)?.format("YYYY-MM-DD");
          let itemEndDate = null;
          if (item.end !== null) {
            itemEndDate = dayjs(item?.end)?.format("YYYY-MM-DD");
          } else {
            itemEndDate = null;
          }

          let startDateWithinRange = false;
          let endDateWithinRange = false;

          if (!exactMatchData) {
            startDateWithinRange =
              selectedStartDateObj >= itemStartDate &&
              (!itemEndDate || itemEndDate >= selectedStartDateObj);
            endDateWithinRange =
              selectedEndDateObj &&
              itemEndDate &&
              selectedEndDateObj <= itemEndDate &&
              selectedStartDateObj <= itemEndDate;
          }
          return startDateWithinRange || endDateWithinRange;
        });
      }
    }

    const existRequestData = availabilityRequestData?.availabilityRequests;
    const pendingRequestData = existRequestData?.filter(
      (request: { status: string }) => request?.status === PENDING,
    );
    if (pendingRequestData?.length > 0) {
      if (startDate && endDate) {
        let hasMatchingPendingRequest = false;
        pendingRequestData?.forEach((pendingData: any) => {
          if (
            pendingData?.effectiveFrom === startDate &&
            pendingData?.endsAfter === endDate
          ) {
            hasMatchingPendingRequest = true;
          }
        });
        if (!editPendingAvailability) {
          setIsStartAndNullEndDateExist(hasMatchingPendingRequest);
        }
        if (!hasMatchingPendingRequest || editPendingAvailability) {
          setNextStep(!nextStep);
        }
      } else {
        if (startDate && !endDate) {
          let hasMatchingPendingRequest = false;
          pendingRequestData?.forEach((pendingData: any) => {
            if (
              pendingData?.effectiveFrom === startDate &&
              pendingData?.endsAfter === endDate
            ) {
              hasMatchingPendingRequest = true;
            }
          });
          if (!editPendingAvailability) {
            setIsStartAndNullEndDateExist(hasMatchingPendingRequest);
          }
          if (!hasMatchingPendingRequest || editPendingAvailability) {
            setNextStep(!nextStep);
          }
        }
      }
    } else {
      setNextStep(!nextStep);
      setShowStartDateCalendar(false);
      setShowEndDateCalendar(false);
      setIsStartAndNullEndDateExist(false);
    }
    dispatch(editNewAvailabilityRequest(false));
  };

  const onError = (error: any, eventName: string) => {
    setRequestSubmitted(true);
    setIsSubmitSuccessful(false);
    if (editPendingAvailability) {
      setIsEditRequestSubmitSuccessful(false);
    }
    setIsSubmitClicked(false);
    setWeeklyDays([]);
    analytics?.trackEvent({
      name: eventName,
      properties: {
        page: "Availability",
        workflow: "Create",
        component: "Create",
        error: error,
      },
    });
    console.error(`${eventName}: ${error}`);
  };

  const onSuccess = (response: any, eventName: string) => {
    if (response.data) {
      analytics?.trackEvent({
        name: eventName,
        properties: {
          page: "Availability",
          workflow: "Create",
          component: "Create",
        },
      });
      setIsSubmitClicked(false);
      setRequestSubmitted(true);
      setIsSubmitSuccessful(true);
      if (editPendingAvailability) {
        setIsEditRequestSubmitSuccessful(true);
        dispatch(setEditPendingAvailability(false));
      }
      dispatch(setCopyCurrentAvailability(false));
      setWeeklyDays([]);
      setTimeout(() => {
        void dispatch(getAvailabilityAll());
      }, 2000);
    } else {
      setIsSubmitClicked(false);
      analytics?.trackEvent({
        name: `Error: API error`,
        properties: {
          page: "Availability",
          workflow: "Create",
          component: "Create",
          error: response,
        },
      });
      console.error("API Error:", response.errorResponse.message);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setIsSubmitClicked(true);
    idCounterRef.current += 1;

    const postEditNewAvailability = returnAvailabilityToCancelPostEdit(
      defaultWeeklyDays,
      updatedWeekDaysStore,
      comment,
      startDate,
      endDate,
      editPendingAvailabilityData.id,
      false,
      editPendingAvailability,
      editPendingAvailabilityData,
    );

    if (editPendingAvailability) {
      await dispatch(updateAvailabilityRequest(postEditNewAvailability))
        .then((action: any) => {
          const response = action.payload;
          onSuccess(response, `Success: Edited Pending Availability`);
        })
        .catch((error: any) => {
          onError(error, `Error: Error updating pending Availability Request`);
        });
    } else {
      await dispatch(makeAvailabilityRequest(postEditNewAvailability))
        .then((action) => {
          const response = action.payload;
          onSuccess(response, `Success: Created Availability`);
        })
        .catch((error) => {
          onError(error, `Error: Error creating Availability Request`);
        });
    }
  };

  const onSuccessRetry = (response: any, eventName: string) => {
    if (response.data) {
      analytics?.trackEvent({
        name: eventName,
        properties: {
          page: "Availability",
          workflow: "Create",
          component: "Create",
        },
      });
      setIsResubmitClicked(false);
      setIsSubmitSuccessful(true);
      if (editPendingAvailability) {
        setIsEditRequestSubmitSuccessful(true);
        dispatch(setEditPendingAvailability(false));
      }
      setTimeout(() => {
        void dispatch(getAvailabilityAll());
      }, 2000);
      dispatch(setCopyCurrentAvailability(false));
      setWeeklyDays([]);
      setShowLoader(false);
    } else {
      analytics?.trackEvent({
        name: `Error: API error`,
        properties: {
          page: "Availability",
          workflow: "Create",
          component: "Create",
          error: response,
        },
      });
      console.error("API Error:", response.statusText);
    }
  };

  const onErrorRetry = (
    error: any,
    eventNameBeforeMaxAttempt: string,
    eventNameAfterMaxAttempt: string,
  ) => {
    analytics?.trackEvent({
      name: eventNameBeforeMaxAttempt,
      properties: {
        page: "Availability",
        workflow: "Create",
        component: "Create",
        error: error,
      },
    });
    setShowLoader(false);
    setIsResubmitClicked(false);
    if (retryAttemptAvailability >= 3) {
      setIsSubmitSuccessful(false);
      if (editPendingAvailability) {
        setIsEditRequestSubmitSuccessful(false);
        dispatch(setEditPendingAvailability(false));
      }
      setMaxResubmitAttemptsReached(true);
      setWeeklyDays([]);
      analytics?.trackEvent({
        name: eventNameAfterMaxAttempt,
        properties: {
          page: "Availability",
          workflow: "Create",
          component: "Create",
          error: error,
        },
      });
      console.error("Error creating Availability Request:", error);
    }
  };

  useEffect(() => {
    dispatch(setPreferredShifts({}));
  }, []);

  useEffect(() => {
    const handleRetry = async () => {
      setShowLoader(true);
      idCounterRef.current += 1;

      const postEditNewAvailability = returnAvailabilityToCancelPostEdit(
        defaultWeeklyDays,
        updatedWeekDaysStore,
        comment,
        startDate,
        endDate,
        editPendingAvailabilityData.id,
        false,
        editPendingAvailability,
        editPendingAvailabilityData,
      );

      if (editPendingAvailability) {
        await dispatch(updateAvailabilityRequest(postEditNewAvailability))
          .then((action: any) => {
            const response = action.payload;
            onSuccessRetry(
              response,
              `Success: Edit Pending Availability on Retry`,
            );
          })
          .catch((error: any) => {
            onErrorRetry(
              error,
              `Error: Error editing Availability Request. Attempt number ${retryAttemptAvailability}`,
              `Error: Error editing Availability Request. No attempts remaining`,
            );
          });
      } else {
        await dispatch(makeAvailabilityRequest(postEditNewAvailability))
          .then((action) => {
            const response = action.payload;
            onSuccessRetry(
              response,
              `Success: Submitted Availability on Retry`,
            );
            return;
          })
          .catch((error) => {
            onErrorRetry(
              error,
              `Error: Error creating Availability Request. Attempt number ${retryAttemptAvailability}`,
              `Error: Error creating Availability Request. No attempts remaining`,
            );
          });
      }
    };
    if (startDate) {
      void handleRetry();
    }
  }, [retryAttemptAvailability]);

  const clickClose = () => {
    analytics?.trackEvent({
      name: `Close Create button clicked`,
      properties: {
        page: "Availability",
        workflow: "Create",
        component: "Close button",
      },
    });
    if (
      (isSubmitSuccessful && nextStep && isRequestSubmitted) ||
      maxResubmitAttemptsReached
    ) {
      dispatch(setRetryAttemptAvailability(0));
      dispatch(setOnHomeScreen(true));
      if (editPendingAvailability) {
        setIsEditRequestSubmitSuccessful(false);
      }
      setIsLoading(false);
      clearStateValue();
      toggleDrawer(false);
    } else if (editPendingAvailability) {
      setIsLoading(false);
      setOpenConfirmModal(!openConfirmModal);
      setIsEditRequestSubmitSuccessful(false);
    } else {
      setIsLoading(false);
      setOpenConfirmModal(!openConfirmModal);
    }
  };

  const closeConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  return (
    <>
      {isLoading && onHomeScreen ? (
        <Loader fullScreen={true} />
      ) : (
        <>
          <CustomDrawer
            anchor={"bottom"}
            toggleDrawer={toggleDrawer}
            drawerClass={"drawer bottom"}
            closeBtnClass={"close"}
            drawerTitle={
              isSetIdealShifts === true
                ? translate("SET_IDEAL_SHIFTS")
                : translate("NEW_AVAILABILITY_REQUEST")
            }
            titleClass={"drawer-title"}
            onCustomAction={() => {
              clickClose();
            }}
            page={"availabilityCreate"}
            preferenceError={isPrefError}
          >
            {windowWidth < smallDesktop ? (
              <Grid display={"flex"} flexDirection={"column"} flexGrow={1}>
                <Grid zIndex={6}>
                  <Grid item xs spacing={3} className={"titleBox"}>
                    <span>{translate("NEW_AVAILABILITY_REQUEST")}</span>
                  </Grid>
                  {!isRequestSubmitted && preferSuccess && (
                    <>
                      <Grid
                        container
                        xs={12}
                        item
                        alignItems={"center"}
                        tabIndex={-1}
                        className={"buttonControl"}
                      >
                        <Button
                          onClick={() => {
                            analytics?.trackEvent({
                              name: `Cancel button clicked`,
                              properties: {
                                page: "Availability",
                                workflow: "Create",
                                component: "Submit Button",
                              },
                            });
                            dispatch(setIdealShifts(false));
                            clickClose();
                          }}
                          disabled={false}
                          className={"submit-btn button-cancel-btn-mobile"}
                        >
                          {translate("CANCEL")}
                        </Button>
                        <CustomBtn
                          text={translate("SUBMIT")}
                          onClick={() => {
                            analytics?.trackEvent({
                              name: `Submit button clicked`,
                              properties: {
                                page: "Availability",
                                workflow: "Create",
                                component: "Submit Button",
                              },
                            });
                            void handleSubmit();
                          }}
                          className={"submit-btn create-submit-btn-mobile"}
                          disabled={
                            !requirementMeet || isSubmitClicked || !nextStep
                          }
                          loading={isSubmitClicked}
                          circularProgressClass="progress"
                        />
                      </Grid>
                      <Step
                        stepTitle={translate("START_&_END_DATE")}
                        setNextStep={setNextStep}
                        nextStep={nextStep}
                        stepClass={
                          !nextStep
                            ? "Step Step1 active"
                            : "Step Step1 completed"
                        }
                        step1={true}
                        dispatch={dispatch}
                        isRequestSubmitted={isRequestSubmitted}
                      />

                      <Grid
                        item
                        xs={12}
                        md={6}
                        justifyContent={"center"}
                        tabIndex={-1}
                        className="date-header-grid"
                      >
                        {nextStep && startDate ? (
                          <DateHeader
                            startDate={startDate ? startDate : undefined}
                            endDate={endDate ? endDate : undefined}
                            onClick={() => {
                              analytics?.trackEvent({
                                name: "Return to date selection",
                                properties: {
                                  page: "Availability",
                                  workflow: "Create",
                                  component: "DateHeader",
                                },
                              });
                              setNextStep(!nextStep);
                            }}
                          />
                        ) : (
                          <DatePick
                            showStartDateCalendar={showStartDateCalendar}
                            setShowStartDateCalendar={setShowStartDateCalendar}
                            showEndDateCalendar={showEndDateCalendar}
                            setShowEndDateCalendar={setShowEndDateCalendar}
                            startDate={
                              isStartDateDisabled ? undefined : startDate
                            }
                            endDate={isEndDateDisabled ? undefined : endDate}
                            handleStartDate={handleStartDate}
                            handleEndDate={handleEndDate}
                            onStartDateDisabled={setIsStartDateDisabled}
                            onEndDateDisabled={setIsEndDateDisabled}
                            onSetEndDate={setEndDate}
                            onSetStartDate={setStartDate}
                            isStartAndNullEndDateExist={
                              isStartAndNullEndDateExist
                            }
                            setIsStartAndNullEndDateExist={
                              setIsStartAndNullEndDateExist
                            }
                            allowFromDays={allowFromDays}
                          />
                        )}
                      </Grid>
                    </>
                  )}
                  {!nextStep && !isRequestSubmitted && preferSuccess && (
                    <Grid item xs={12} className={"next-btn-box"}>
                      <CustomBtn
                        text={translate("NEXT")}
                        onClick={() => {
                          analytics?.trackEvent({
                            name: `Next button clicked`,
                            properties: {
                              page: "Availability",
                              workflow: "Create",
                              component: "Next Button",
                            },
                          });
                          onNextBtn(nextStep);
                        }}
                        className="next-btn secondary-btn"
                        disabled={
                          isStartDateDisabled ||
                          isStartAndEndDateAlreadyExist ||
                          !startDate ||
                          startDate === endDate ||
                          endDate < startDate ||
                          dayjs(startDate)
                            .startOf("day")
                            .isBefore(dayjs().startOf("day"), "day") ||
                          dayjs(startDate)
                            .startOf("day")
                            .diff(dayjs().startOf("day"), "day") <
                            Number(allowFromDays)
                        }
                      />
                    </Grid>
                  )}
                  {!isRequestSubmitted && preferSuccess && (
                    <>
                      <Grid item xs={12} md={6}>
                        <Step
                          stepTitle={translate("WEEKLY_AVAILABILITY")}
                          setNextStep={setNextStep}
                          nextStep={nextStep}
                          stepClass={
                            nextStep ? "Step Step2 active" : "Step Step2"
                          }
                          step1={false}
                          dispatch={dispatch}
                          isRequestSubmitted={isRequestSubmitted}
                        />
                      </Grid>
                    </>
                  )}
                  {!isRequestSubmitted && preferSuccess && nextStep && (
                    <RequestCondition
                      backgroundColorClass={
                        requirementMeet
                          ? "messageBox success"
                          : "messageBox info"
                      }
                      totalHours={totalHoursNumber}
                    />
                  )}
                </Grid>
                {((nextStep && !isRequestSubmitted) ||
                  (editPendingAvailability && !isRequestSubmitted)) &&
                  !editBtnDateHeaderFlag &&
                  preferSuccess && (
                    <Grid item xs={12} md={6} className="add-request-section">
                      <AddRequest
                        comment={comment}
                        setComment={setComment}
                        weeklyDays={weeklyDays}
                        setWeeklyDays={setWeeklyDays}
                        totalHours={totalHours}
                        setTotalHours={setTotalHours}
                      />
                    </Grid>
                  )}
                {((!isSubmitSuccessful && nextStep && isRequestSubmitted) ||
                  (isPrefError === true && preferSuccess === false)) && (
                  <ResultMessage
                    title={
                      isPrefError
                        ? translate("SYSTEM_ERROR")
                        : translate("AVAILABILITY_POST_REQUEST_FAILURE_MSG")
                    }
                    subTitle={
                      maxResubmitAttemptsReached
                        ? translate("TRY_AGAIN_LATER_AVAILABILITY")
                        : isPrefError
                          ? translate("SYSTEM_ERROR_MESSAGE")
                          : translate("RESUBMIT_MSG")
                    }
                    error={true}
                    success={false}
                    onClick={() => {
                      analytics?.trackEvent({
                        name: `Retry Submit`,
                        properties: {
                          page: "Availability",
                          workflow: "Create",
                          component: "Result Message",
                        },
                      });
                      if (maxResubmitAttemptsReached || isPrefError) {
                        clickClose();
                      } else {
                        dispatch(
                          setRetryAttemptAvailability(
                            retryAttemptAvailability + 1,
                          ),
                        );
                        setIsResubmitClicked(true);
                      }
                    }}
                    open
                    buttonText={
                      maxResubmitAttemptsReached || isPrefError
                        ? translate("CLOSE")
                        : translate("RESUBMIT")
                    }
                    buttonDisabled={isResubmitClicked}
                    showLoader={!maxResubmitAttemptsReached && showLoader}
                  />
                )}
                {isSubmitSuccessful && nextStep && isRequestSubmitted && (
                  <ResultMessage
                    title={translate("SUCCESS")}
                    subTitle={
                      isEditRequestSubmitSuccessful === true
                        ? translate("EDIT_AVAILABILITY_SUCCESS_MSG")
                        : translate("AVAILABILITY_POST_REQUEST_SUCCESS_MSG")
                    }
                    error={false}
                    success={true}
                    onClick={() => {
                      analytics?.trackEvent({
                        name: `Retry Close`,
                        properties: {
                          page: "Availability",
                          workflow: "Create",
                          component: "Result Message",
                        },
                      });
                      clickClose();
                    }}
                    open
                    buttonText={translate("CLOSE")}
                    buttonDisabled={isResubmitClicked}
                    showLoader={showLoader}
                  />
                )}
              </Grid>
            ) : (
              <>
                <Grid className={"create-header"}>
                  <Typography id={"header-text"}>
                    {translate("NEW_AVAILABILITY_REQUEST")}
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  direction={"row"}
                  alignItems={"stretch"}
                >
                  <Grid className={"leftPane"} tabIndex={-1}>
                    <Grid container alignItems={"center"}>
                      <Step
                        stepTitle={translate("START_&_END_DATE")}
                        setNextStep={setNextStep}
                        nextStep={nextStep}
                        stepClass={
                          !nextStep
                            ? "Step Step1 active"
                            : "Step Step1 completed"
                        }
                        step1={true}
                        dispatch={dispatch}
                        isStartDateExist={isSubmitSuccessful} // Disables edit as desired once submit has succeeded.
                        isRequestSubmitted={isRequestSubmitted}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {nextStep &&
                        startDate &&
                        !isSubmitSuccessful &&
                        preferSuccess && (
                          <DateHeader
                            startDate={startDate ? startDate : undefined}
                            endDate={endDate ? endDate : undefined}
                            onClick={() => {
                              analytics?.trackEvent({
                                name: `Next button clicked`,
                                properties: {
                                  page: "Availability",
                                  workflow: "Create",
                                  component: "Next Button",
                                },
                              });
                              setNextStep(!nextStep);
                            }}
                          />
                        )}
                    </Grid>
                    {
                      <Grid item xs={12}>
                        <Step
                          stepTitle={translate("WEEKLY_AVAILABILITY")}
                          setNextStep={setNextStep}
                          nextStep={nextStep}
                          stepClass={
                            nextStep ? "Step Step2 active" : "Step Step2"
                          }
                          step1={false}
                          dispatch={dispatch}
                          isRequestSubmitted={isRequestSubmitted}
                        />
                      </Grid>
                    }
                    {nextStep && (
                      <>
                        <RequestCondition
                          backgroundColorClass={
                            requirementMeet
                              ? "messageBox success"
                              : "messageBox info"
                          }
                          totalHours={totalHoursNumber}
                        />
                        <Grid xs={12} item>
                          <AddComment
                            comment={comment}
                            setComment={setComment}
                            updateComment={setComment}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid className={"rightPane"} tabIndex={-1}>
                    <Grid className="rightPanelItem">
                      <SimpleBar className="rightPanelHeight" tabIndex={-1}>
                        <Grid item className="rightPanelItems avail-drawer">
                          {!nextStep &&
                            !isRequestSubmitted &&
                            preferSuccess && (
                              <Grid
                                xs={12}
                                item
                                container
                                className="step1-items"
                              >
                                <Grid xs={12} item className={"section-title"}>
                                  <TypoGraphy
                                    variant="h1"
                                    typeClass={"regular-font"}
                                  >
                                    {translate("START_&_END_DATE")}
                                  </TypoGraphy>
                                </Grid>

                                <DatePick
                                  showStartDateCalendar={showStartDateCalendar}
                                  setShowStartDateCalendar={
                                    setShowStartDateCalendar
                                  }
                                  showEndDateCalendar={showEndDateCalendar}
                                  setShowEndDateCalendar={
                                    setShowEndDateCalendar
                                  }
                                  startDate={
                                    isStartDateDisabled ? undefined : startDate
                                  }
                                  endDate={
                                    isEndDateDisabled ? undefined : endDate
                                  }
                                  handleStartDate={handleStartDate}
                                  handleEndDate={handleEndDate}
                                  onStartDateDisabled={setIsStartDateDisabled}
                                  onEndDateDisabled={setIsEndDateDisabled}
                                  onSetStartDate={setStartDate}
                                  onSetEndDate={setEndDate}
                                  isStartAndNullEndDateExist={
                                    isStartAndNullEndDateExist
                                  }
                                  setIsStartAndNullEndDateExist={
                                    setIsStartAndNullEndDateExist
                                  }
                                  allowFromDays={allowFromDays}
                                />
                              </Grid>
                            )}
                          {((nextStep && !isRequestSubmitted) ||
                            (editPendingAvailability && !isRequestSubmitted)) &&
                            !editBtnDateHeaderFlag &&
                            preferSuccess && (
                              <Grid
                                item
                                xs={12}
                                className="add-request-section"
                              >
                                <AddRequest
                                  comment={comment}
                                  setComment={setComment}
                                  weeklyDays={weeklyDays}
                                  setWeeklyDays={setWeeklyDays}
                                  totalHours={totalHours}
                                  setTotalHours={setTotalHours}
                                />
                              </Grid>
                            )}
                          {((!isSubmitSuccessful &&
                            nextStep &&
                            isRequestSubmitted) ||
                            (isPrefError === true &&
                              preferSuccess === false)) && (
                            <ResultMessage
                              title={
                                isPrefError
                                  ? translate("SYSTEM_ERROR")
                                  : translate(
                                      "AVAILABILITY_POST_REQUEST_FAILURE_MSG",
                                    )
                              }
                              subTitle={
                                maxResubmitAttemptsReached
                                  ? translate("TRY_AGAIN_LATER_AVAILABILITY")
                                  : isPrefError
                                    ? translate("SYSTEM_ERROR_MESSAGE")
                                    : translate("RESUBMIT_MSG")
                              }
                              error={true}
                              success={false}
                              onClick={() => {
                                analytics?.trackEvent({
                                  name: `Retry Submit`,
                                  properties: {
                                    page: "Availability",
                                    workflow: "Create",
                                    component: "Result Message",
                                  },
                                });
                                if (maxResubmitAttemptsReached || isPrefError) {
                                  clickClose();
                                } else {
                                  dispatch(
                                    setRetryAttemptAvailability(
                                      retryAttemptAvailability + 1,
                                    ),
                                  );
                                  setIsResubmitClicked(true);
                                }
                              }}
                              open
                              buttonText={
                                maxResubmitAttemptsReached || isPrefError
                                  ? translate("CLOSE")
                                  : translate("RESUBMIT")
                              }
                              buttonDisabled={isResubmitClicked}
                              showLoader={
                                !maxResubmitAttemptsReached && showLoader
                              }
                            />
                          )}
                          {isSubmitSuccessful &&
                            nextStep &&
                            isRequestSubmitted && (
                              <ResultMessage
                                title={translate("SUCCESS")}
                                subTitle={
                                  isEditRequestSubmitSuccessful === true
                                    ? translate("EDIT_AVAILABILITY_SUCCESS_MSG")
                                    : translate(
                                        "AVAILABILITY_POST_REQUEST_SUCCESS_MSG",
                                      )
                                }
                                error={false}
                                success={true}
                                onClick={() => {
                                  analytics?.trackEvent({
                                    name: `Retry Close`,
                                    properties: {
                                      page: "Availability",
                                      workflow: "Create",
                                      component: "Result Message",
                                    },
                                  });
                                  clickClose();
                                }}
                                open
                                buttonText={translate("CLOSE")}
                                buttonDisabled={isResubmitClicked}
                                showLoader={showLoader}
                              />
                            )}
                        </Grid>
                      </SimpleBar>
                    </Grid>
                  </Grid>
                </Grid>
                <div className={"create-footer"}>
                  <Grid className={"buttonArea"}>
                    <Button
                      onClick={() => {
                        analytics?.trackEvent({
                          name: `Cancel button clicked`,
                          properties: {
                            page: "Availability",
                            workflow: "Create",
                            component: "Cancel Button",
                          },
                        });
                        dispatch(setIdealShifts(false));
                        clickClose();
                      }}
                      disabled={false}
                      className={"cancel-btn-create"}
                    >
                      {translate("CANCEL")}
                    </Button>
                    {!nextStep && (
                      <Button
                        onClick={() => {
                          analytics?.trackEvent({
                            name: `Next button clicked`,
                            properties: {
                              page: "Availability",
                              workflow: "Create",
                              component: "Next Button",
                            },
                          });
                          onNextBtn(nextStep);
                        }}
                        className={"next-btn-create"}
                        disabled={
                          isStartDateDisabled ||
                          isStartAndEndDateAlreadyExist ||
                          !startDate ||
                          startDate === endDate ||
                          endDate < startDate ||
                          dayjs(startDate)
                            .startOf("day")
                            .isBefore(dayjs().startOf("day"), "day") ||
                          dayjs(startDate)
                            .startOf("day")
                            .diff(dayjs().startOf("day"), "day") <
                            Number(allowFromDays)
                        }
                      >
                        {translate("NEXT")}
                      </Button>
                    )}
                    {nextStep && (
                      <Grid item xs={12} textAlign={"right"}>
                        {(() => {
                          allWeekDays?.filter((day) => {
                            const existingDay = weeklyDays?.find(
                              (weekDay) =>
                                weekDay.dayOfWeek === day &&
                                (weekDay.generalAvailabilityTimings === null ||
                                  (weekDay.generalAvailabilityTimings &&
                                    weekDay.generalAvailabilityTimings?.length >
                                      0)),
                            );
                            return !existingDay;
                          });
                          return (
                            <CustomBtn
                              text={translate("SUBMIT")}
                              onClick={() => {
                                analytics?.trackEvent({
                                  name: `Submit button clicked`,
                                  properties: {
                                    page: "Availability",
                                    workflow: "Create",
                                    component: "Submit Button",
                                  },
                                });
                                void handleSubmit();
                              }}
                              className="submit-btn secondary-btn"
                              disabled={
                                !startDate ||
                                !requirementMeet ||
                                isSubmitClicked
                              }
                              loading={isSubmitClicked}
                              circularProgressClass="progress"
                            />
                          );
                        })()}
                      </Grid>
                    )}
                  </Grid>
                </div>
              </>
            )}
            {isSetIdealShifts === true && windowWidth < smallDesktop && (
              <IdealShifts weeklyDays={weeklyDays} />
            )}
            {isSetIdealShifts === true && windowWidth > smallDesktop && (
              <div className="modalDivIS">
                <Modal
                  open={isSetIdealShifts}
                  className={"desktopIdealShiftPopup"}
                >
                  <IdealShifts weeklyDays={weeklyDays} />
                </Modal>
              </div>
            )}
          </CustomDrawer>
          {openConfirmModal && (
            <ConfirmDialog
              openConfirmModal={openConfirmModal}
              closeModal={closeConfirmModal}
              handleConfirmModal={() => {
                toggleDrawer(false);
                clearStateValue();
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default Create;
