import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/store";
import availabilityRequestInfoIcon from "../../../../styles/assets/svg/availabilityRequestInfoIcon.svg";
import availabilityRequestSuccessMsgIcon from "../../../../styles/assets/svg/availabilityRequestSuccessMsgIcon.svg";
import { useEffect, useState } from "react";

interface Props {
  backgroundColorClass: string;
  totalHours: number;
}

const RequestCondition: React.FC<Props> = ({
  backgroundColorClass,
  totalHours,
}) => {
  const { t: translate } = useTranslation();
  const { storeData } = useAppSelector((state: any) => state?.userStoreData);
  const { preferredHoursFor150compliance } = useAppSelector(
    (state: any) => state?.meta,
  );
  const [resultHours, setResultHours] = useState<any>(0);

  useEffect(() => {
    /** This method calculates the hours required to display in request info message based on preferred hours and minimum availability hours */
    const return150ComplianceHoursForDisplay = () => {
      let resultHours = 0;

      if (totalHours == 0) {
        if (
          preferredHoursFor150compliance &&
          preferredHoursFor150compliance !== 0
        ) {
          resultHours = preferredHoursFor150compliance * 1.5;
        } else {
          resultHours = storeData.minAvailabilityHours;
        }
      } else {
        if (
          preferredHoursFor150compliance &&
          preferredHoursFor150compliance !== 0
        ) {
          resultHours = preferredHoursFor150compliance * 1.5 - totalHours;
        } else {
          resultHours = storeData.minAvailabilityHours - totalHours;
        }
      }
      setResultHours(resultHours);
    };
    return150ComplianceHoursForDisplay();
  }, [totalHours]);

  return (
    <div className={backgroundColorClass}>
      <div className={"messageContainer"}>
        <div className={"containerDiv"}>
          <img
            className={"availabilityRequestInfoIcon"}
            src={
              backgroundColorClass === "messageBox info"
                ? availabilityRequestInfoIcon
                : availabilityRequestSuccessMsgIcon
            }
            alt={
              backgroundColorClass === "messageBox info"
                ? "availabilityRequestInfoIcon"
                : "availabilityRequestSuccessMsgIcon"
            }
          />
        </div>
        <div className={"request-condition"}>
          <Typography id={"mainText"}>
            {`${translate("AVAILABILITY_TOTAL_HRS_MSG")}`}
          </Typography>
          {totalHours === 0 && (
            <Typography id={"subText"}>
              {translate("AVAILABILITY_MINIMUM_HRS_MSG", {
                totalHours: resultHours,
              })}
            </Typography>
          )}
          {totalHours !== 0 &&
            backgroundColorClass !== "messageBox success" && (
              <span>
                <Typography id={"subText"}>
                  {translate("DOES_NOT_MEET_REQUIREMENTS_MESSAGE_1")}
                </Typography>
                <Typography id={"subTextHour"}>{resultHours} </Typography>
                <Typography id="subTextHour">{translate("H")}</Typography>
                <Typography id={"subText"}>
                  {translate("DOES_NOT_MEET_REQUIREMENTS_MESSAGE_2")}
                </Typography>
              </span>
            )}
          {backgroundColorClass === "messageBox success" && (
            <Typography id={"subText"}>
              {translate("MEETS_REQUIREMENTS_MESSAGE")}
            </Typography>
          )}
        </div>
        <div id={"total-hours"}>
          <Typography display="inline" variant="body1" id={"totalHourValue"}>
            {totalHours} {translate("H")}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default RequestCondition;
