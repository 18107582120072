export const formatDateString = (
  isEndDate: boolean,
  dateString: string | undefined,
  isToday: (dateString: string) => boolean,
  getMonthTranslation: (monthIndex: number, type: "short" | "full") => string,
  translate: (key: string) => string,
  language: string | undefined,
): string => {
  if (!dateString) {
    return translate("NO_END_DATE");
  }

  const originalDateString = dateString ? dateString.split("T")[0] : "";

  if (language === "fr") {
    return originalDateString;
  }

  if (language === "en") {
    const year = parseInt(originalDateString.slice(0, 4));
    const monthIndex = parseInt(originalDateString.slice(5, 7)) - 1;
    const day = parseInt(originalDateString.slice(8));

    const formattedDate =
      isToday(originalDateString) && !isEndDate
        ? translate("TODAY")
        : `${getMonthTranslation(monthIndex, "short")} ${day}, ${year}`;

    return formattedDate;
  }

  return "";
};

export const formatDateStringWithoutToday = (
  dateString: string | undefined,
  isToday: (dateString: string) => boolean,
  getMonthTranslation: (monthIndex: number, type: "short" | "full") => string,
  translate: (key: string) => string,
  language: string | undefined,
): string => {
  if (!dateString) {
    return translate("NO_END_DATE");
  }

  const originalDateString = dateString ? dateString.split("T")[0] : "";
  isToday(originalDateString);

  if (language === "fr") {
    return originalDateString;
  }

  if (language === "en") {
    const year = parseInt(originalDateString.slice(0, 4));
    const monthIndex = parseInt(originalDateString.slice(5, 7)) - 1;
    const day = parseInt(originalDateString.slice(8));

    return `${getMonthTranslation(monthIndex, "short")} ${day}, ${year}`;
  }
  return "";
};

export const formatDateStringToTime = (
  dateString: string | undefined,
): string => {
  if (dateString === undefined) {
    return "Invalid Date";
  }

  const hours = parseInt(dateString.slice(11, 13));
  const minutes = parseInt(dateString.slice(14, 16));
  // const seconds = parseInt(originalDateString.slice(17, 19));

  const formattedTime = `${(hours % 12).toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${hours < 12 ? "AM" : "PM"}`;

  return formattedTime;
};

export interface IdealShiftObject {
  startTimeOffset: string;
  endTimeOffset: string;
}

interface GeneralAvailabilityTimings {
  id: number;
  startTimeOffset: string;
  endTimeOffset: string;
  preferredAvailabilityTimings: IdealShiftObject[];
}

interface DayData {
  dayOfWeek: string;
  weekNumber: number;
  generalAvailabilityTimings: GeneralAvailabilityTimings[];
}

export const getTotalHrs = (data: DayData[] | undefined): number => {
  let totalHours = 0;

  if (data && data.length > 0) {
    data.forEach((item: any) => {
      if (item.generalAvailabilityTimings) {
        item.generalAvailabilityTimings.forEach((timing: any) => {
          if (timing?.workingHours) {
            const [hours, minutes] = timing.workingHours.split(":").map(Number);
            totalHours += hours + minutes / 60;
          }
        });
      }
    });
  }

  return Number(totalHours.toFixed(2));
};

export const formatTimeForNewRequestTime = (time: string): string => {
  const [hours, minutes] = time.split(":").map(Number);

  if (hours !== 0) {
    if (hours === 0 && minutes !== 0) {
      return `${hours?.toString().padStart(2, "0")}:${minutes?.toString().padStart(2, "0")}`;
    } else {
      return hours.toString();
    }
  } else {
    return "0";
  }
};

export const convertTo24Hour = (timeStr: string) => {
  const [time, period] = timeStr.split(" ");
  const [hours, minutes] = time.split(":").map(Number);

  let adjustedHours = hours;

  if (period === "PM" && hours < 12) {
    adjustedHours += 12;
  } else if (period === "AM" && hours === 12) {
    adjustedHours = 0;
  }

  return `${adjustedHours?.toString().padStart(2, "0")}:${minutes?.toString().padStart(2, "0")}`;
};

export const convertTo24HourEndTime = (timeStr: string) => {
  const [time, period] = timeStr.split(" ");
  const [hours, minutes] = time.split(":").map(Number);

  let adjustedHours = hours;

  if (period === "PM" && hours < 12) {
    adjustedHours += 12;
  } else if (period === "AM" && hours === 12 && minutes === 0) {
    adjustedHours = 24;
  } else if (period === "AM" && hours === 12 && minutes !== 0) {
    adjustedHours = 0;
  }

  return `${adjustedHours?.toString().padStart(2, "0")}:${minutes?.toString().padStart(2, "0")}`;
};

export const convertTo24HourWithSeconds = (timeStr: string) => {
  const [time, period] = timeStr.split(" ");
  const [hours, minutes] = time.split(":").map(Number);

  let adjustedHours = hours;

  if (period === "PM" && hours < 12) {
    adjustedHours += 12;
  } else if (period === "AM" && hours === 12) {
    adjustedHours = 0;
  }

  return `${adjustedHours?.toString().padStart(2, "0")}:${minutes?.toString().padStart(2, "0")}:00`;
};
