import { Grid, IconButton, Stack } from "@mui/material";
import "../../../../styles/drawer.scss";
import "./style.scss";
import CustomDrawer from "../../../common/Drawer";
import { useContext, useEffect, useState } from "react";
import CustomBtn from "../../../common/Button";
import DateHeader from "./DateHeader";
import Step from "./Step";
import CustomDivider from "../../../common/Divider";
import PreferredCard from "./PreferredCard";
import DatePick from "./DatePick";
import useViewportWidth from "../../../../utils/useViewportWidth";
import { PENDING, smallDesktop } from "../../../GlobalConstants";
import TypoGraphy from "../../../common/Typography";
import { CloseRounded } from "@mui/icons-material";
import SimpleBar from "simplebar-react";
import ConfirmDialog from "../../../common/ConfirmDialog";
import { useTranslation } from "react-i18next";
import {
  createPreference,
  getPreferences,
  updatePreference,
} from "../../../../features/preferencesReducer/action";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import { getTotalHrs } from "../../../../utils/durationCalculate";
import { PreferenceType } from "../constants";
import moment from "moment";
import ResultMessage from "../../../common/ResultMessage";
import {
  setOnHomeScreen,
  setRetryAttemptPreferences,
} from "../../../../features/metaReducer";
import { AnalyticsContext, PageType } from "../../../../analytics";

export interface PreferenceEntryToEdit {
  existingStartDate: string | undefined;
  existingEndDate: string | undefined;
  empConstraintId: number;
}
export interface Props {
  toggleDrawer: (
    open: boolean,
    preferenceEntryToEdit?: PreferenceEntryToEdit,
  ) => void;
  lastPreferredDate?: string;
  editingEnabled: boolean;
  preferenceEntryToEdit?: PreferenceEntryToEdit;
}

const Create: React.FC<Props> = ({
  lastPreferredDate,
  preferenceEntryToEdit,
  editingEnabled,
  toggleDrawer,
}) => {
  const { t: translate } = useTranslation();
  const windowWidth = useViewportWidth();

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [nextStep, setNextStep] = useState<boolean>(false);
  const [isStartDateDisabled, setIsStartDateDisabled] =
    useState<boolean>(false);
  const [isSelectedDayMonday, setIsSelectedDayMonday] =
    useState<boolean>(false);
  const [attributeId, setAttributeId] = useState<any>();
  const [isHourUpdated, setIsHourUpdated] = useState<boolean>(false);
  const [activePreference, setActivePreference] = useState<PreferenceType>({
    attributeAssignmentId: null,
    employeeId: null,
    attributeId: null,
    start: null,
    end: null,
    maxHoursPerWeek: null,
    minHoursPerWeek: null,
    preferredHoursPerWeek: null,
    employeeScheduleConstraintId: null,
    locationNbr: null,
    loginName: null,
  });
  const [hour, setHour] = useState<any>({
    prefer: null,
    minimum: null,
    maximum: null,
  });
  const [currentAvailability, setCurrentAvailability] = useState<any>({});
  const [pendingRequest, setPendingRequest] = useState<any[]>([]);
  const [futureAvailability, setFutureAvailability] = useState<any[]>([]);
  const [totalHours, setTotalHours] = useState<number>(0);
  const [allPreferences, setAllPreferences] = useState<any[]>([]);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);
  const [isRequestSubmitted, setRequestSubmitted] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isResubmitClicked, setIsResubmitClicked] = useState<boolean>(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
  const [pendingData, setPendingData] = useState<any>();
  const [maxResubmitAttemptsReached, setMaxResubmitAttemptsReached] =
    useState<boolean>(false);
  const analytics = useContext(AnalyticsContext);

  useEffect(
    () =>
      analytics?.trackPageView({
        name: "Create Preferences",
        pageType: PageType.NavigatePage,
      }),
    [],
  );

  const validation150 = `${process.env.REACT_APP_PREFERENCE_150_RULE}`;

  const { availabilityRequestData, availabilityData } = useAppSelector(
    (state: any) => state.availabilityAll,
  );
  const { preferencesData } = useAppSelector((state: any) => state.preferences);

  const { preferredHours } = useAppSelector(
    (state: any) => state.preferencesAttribute,
  );
  const { storeData } = useAppSelector((state: any) => state?.userStoreData);

  const minHour = storeData.minPreferredHours;

  const { retryAttemptPreferences } = useAppSelector(
    (state: any) => state.meta,
  );

  const initializePreferences = () => {
    if (preferenceEntryToEdit?.empConstraintId) {
      setStartDate(preferenceEntryToEdit.existingStartDate);
      setEndDate(preferenceEntryToEdit.existingEndDate);

      if (allPreferences && allPreferences.length > 0) {
        const data =
          allPreferences.length === 1
            ? allPreferences[0]
            : allPreferences?.find(
                (item: any) =>
                  Number(item?.employeeScheduleConstraintId) ===
                    Number(preferenceEntryToEdit.empConstraintId) &&
                  item?.start === startDate,
              );
        setActivePreference({
          ...data,
          attributeAssignmentId: data?.attributeAssignmentId,
          employeeId: data?.employeeId,
          attributeId: data?.attributeId,
          employeeScheduleConstraintId: data?.employeeScheduleConstraintId,
          start: data?.start,
          end: data?.end,
          minHoursPerWeek: data?.minHoursPerWeek,
          maxHoursPerWeek: data?.maxHoursPerWeek,
          preferredHoursPerWeek: data?.preferredHoursPerWeek,
          locationNbr: storeData?.locationInfo?.locationNbr,
          loginName: storeData?.loginName,
        });
      }
    }
  };

  const dispatch = useAppDispatch();
  dayjs.extend(updateLocale);

  useEffect(() => {
    const currentData = preferencesData?.currentPreferredHours;
    const futureData = preferencesData?.futurePreferredHours;
    const allData: any[] = [];
    allData.push(currentData);
    for (let i = 0; i < futureData?.length; i++) {
      allData.push(futureData[i]);
    }
    setAllPreferences(allData);
  }, [preferencesData]);

  useEffect(() => {
    dispatch(setOnHomeScreen(false));
    initializePreferences();
  }, []);

  useEffect(() => {
    const currentData: any = availabilityData?.currentAvailability;
    const futureData: any = availabilityData?.futureAvailabilities;
    setCurrentAvailability(currentData);
    setFutureAvailability(futureData);
  }, [availabilityData]);

  useEffect(() => {
    const pendingReqData: any[] =
      availabilityRequestData?.availabilityRequests?.filter(
        (item: any) => item.status === PENDING,
      );

    setPendingRequest(pendingReqData);
  }, [availabilityRequestData]);

  useEffect(() => {
    initializePreferences();
  }, [preferenceEntryToEdit, allPreferences, startDate]);

  useEffect(() => {
    const currenWeekMonday = moment().startOf("isoWeek")?.format("YYYY-MM-DD");
    if (preferenceEntryToEdit && editingEnabled) {
      setStartDate(currenWeekMonday);
    }
  }, [editingEnabled, preferenceEntryToEdit, startDate]);

  useEffect(() => {
    if (activePreference) {
      setHour({
        ...hour,
        minimum: activePreference?.minHoursPerWeek,
        maximum: activePreference?.maxHoursPerWeek,
        prefer: activePreference?.preferredHoursPerWeek,
      });
    }
  }, [activePreference]);

  useEffect(() => {
    const attId: any = preferredHours?.entities?.find(
      (item: any) => parseInt(item?.code) === Number(hour?.prefer),
    )?.id;
    setAttributeId(attId);

    if (isHourUpdated) {
      if (hour?.prefer < hour?.minimum) {
        setHour({
          ...hour,
          minimum: hour?.prefer,
        });
      }

      if (hour?.maximum < hour?.prefer) {
        setHour({
          ...hour,
          maximum: hour?.prefer,
        });
      }
    }
  }, [isHourUpdated, hour, retryAttemptPreferences]);

  useEffect(() => {
    if (startDate) {
      const hoursArr: any[] = [];
      // converting startdate to UTC string (not date) to handle new Date local time conversions.
      const date = new Date(Date.parse(startDate));
      const startDateValueUTC = date.toUTCString();
      const startDateWithoutTime = startDateValueUTC.slice(0, 16);

      const pendingObj = pendingRequest?.filter((item) =>
        checkDateInRange(
          startDateWithoutTime,
          endDate !== null
            ? new Date(endDate)
            : new Date(new Date().setFullYear(new Date().getFullYear() + 20)),
          item?.effectiveFrom,
          item?.endsAfter,
        ),
      );

      setPendingData(pendingObj);
      const futureObj = futureAvailability?.filter((item) =>
        checkDateInRange(
          startDateWithoutTime,
          endDate !== null
            ? new Date(endDate)
            : new Date(new Date().setFullYear(new Date().getFullYear() + 20)),
          dayjs(item?.startDate)?.format("YYYY-MM-DD"),
          item?.endDate !== null
            ? dayjs(item?.endDate)?.format("YYYY-MM-DD")
            : null,
        ),
      );

      const currentObj = [currentAvailability]?.filter((item) =>
        checkDateInRange(
          startDateWithoutTime,
          endDate !== null
            ? new Date(endDate)
            : new Date(new Date().setFullYear(new Date().getFullYear() + 20)),
          dayjs(item?.startDate)?.format("YYYY-MM-DD"),
          item?.endDate !== null
            ? dayjs(item?.endDate)?.format("YYYY-MM-DD")
            : null,
        ),
      );

      if (pendingObj && pendingObj.length !== 0) {
        let item: any;
        for (item of pendingObj) {
          const val = getTotalHrs(item?.availabilities);
          hoursArr.push(val);
        }
      }

      if (futureObj && futureObj.length !== 0) {
        let item: any;
        for (item of futureObj) {
          if (item.weeklyAvailabilityResponseList.length > 0) {
            let weekData: any;
            for (weekData of item.weeklyAvailabilityResponseList) {
              const val = getTotalHrs(weekData.generalAvailability);
              hoursArr.push(val);
            }
          }
        }
      }

      if (currentObj && currentObj.length !== 0) {
        if (currentObj[0] && Object.keys(currentObj[0]).length !== 0) {
          if (currentObj[0].weeklyAvailabilityResponseList.length > 0) {
            let weekData: any;
            for (weekData of currentObj[0].weeklyAvailabilityResponseList) {
              const val = getTotalHrs(weekData.generalAvailability);
              hoursArr.push(val);
            }
          }
        }
      }

      let minimumValFinal = 0;
      minimumValFinal =
        hoursArr.length !== 0 ? Math.min(...hoursArr) : minimumValFinal;
      if (minimumValFinal) {
        setTotalHours(Number(minimumValFinal));
      }
    }
  }, [startDate]);

  useEffect(() => {
    const minVal = Number(minHour);
    if (hour?.prefer && !hour.minimum) {
      setHour({
        ...hour,
        minimum: minVal,
      });
    }
  }, [isHourUpdated, retryAttemptPreferences]);

  const checkDateInRange = (
    checkStartDate: string | number | Date | null,
    checkEndDate: string | number | Date | null,
    recordStartDate: string | number | Date | null,
    recordEndDate: string | number | Date | null,
  ) => {
    const checkStartTimestamp = dayjs(checkStartDate).startOf("day");
    const checkEndTimestamp = dayjs(checkEndDate).startOf("day");
    const recordStartTimestamp = dayjs(recordStartDate).startOf("day");
    const recordEndTimestamp = recordEndDate
      ? dayjs(recordEndDate).startOf("day")
      : dayjs(new Date()).add(20, "years").startOf("day");

    return (
      (checkStartTimestamp >= recordStartTimestamp &&
        checkStartTimestamp <= recordEndTimestamp) ||
      (checkEndTimestamp > recordStartTimestamp &&
        checkEndTimestamp <= recordEndTimestamp) ||
      (checkStartTimestamp < recordStartTimestamp &&
        checkEndTimestamp > recordEndTimestamp)
    );
  };

  const clearStateValue = () => {
    dispatch(setRetryAttemptPreferences(0));
    setStartDate(null);
    setShowCalendar(false);
    setNextStep(false);
    setIsStartDateDisabled(false);
    setHour({
      ...hour,
      prefer: null,
      maximum: null,
      minimum: null,
    });
    setIsHourUpdated(false);
  };

  const handleChange = (e: any) => {
    setIsHourUpdated(true);
    setHour({
      ...hour,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (date: any) => {
    setStartDate(date);
  };

  const clickClose = () => {
    if (
      (isSubmitSuccessful && nextStep && isRequestSubmitted) ||
      maxResubmitAttemptsReached
    ) {
      dispatch(setRetryAttemptPreferences(0));
      dispatch(setOnHomeScreen(true));
      clearStateValue();
      toggleDrawer(false);
    } else {
      setOpenConfirmModal(!openConfirmModal);
    }
  };

  const closeConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const handleConfirm = async () => {
    setIsSubmitClicked(true);
    const PreferCreateObj = {
      employeeId: Number(localStorage.getItem("userEmployeeId")),
      attributeId: Number(attributeId),
      start: dayjs(startDate)?.format("YYYY-MM-DD"),
      end: null,
      maxHoursPerWeek: Number(hour?.maximum),
      minHoursPerWeek: Number(hour?.minimum),
      locationNbr: storeData?.locationInfo?.locationNbr,
      loginName: storeData?.loginName,
    };

    const PreferUpdateObj = {
      employeeScheduleConstraintId: preferenceEntryToEdit?.empConstraintId,
      attributeAssignmentId: activePreference?.attributeAssignmentId,
      employeeId: Number(localStorage.getItem("userEmployeeId")),
      attributeId: Number(attributeId),
      start: moment.utc(startDate)?.format("YYYY-MM-DD"),
      end: preferenceEntryToEdit?.existingEndDate
        ? moment
            .utc(preferenceEntryToEdit.existingEndDate)
            ?.format("YYYY-MM-DD")
        : null,
      maxHoursPerWeek: Number(hour?.maximum),
      minHoursPerWeek: Number(hour?.minimum),
      locationNbr: storeData?.locationInfo?.locationNbr,
      loginName: storeData?.loginName,
    };

    const currenWeekMonday = dayjs().startOf("week")?.format("YYYY-MM-DD");

    const PreferUpdateCurrentObj = {
      employeeScheduleConstraintId: preferenceEntryToEdit?.empConstraintId,
      attributeAssignmentId: activePreference?.attributeAssignmentId,
      employeeId: Number(localStorage.getItem("userEmployeeId")),
      attributeId: Number(attributeId),
      start: currenWeekMonday,
      end: preferenceEntryToEdit?.existingEndDate
        ? dayjs(preferenceEntryToEdit.existingEndDate)?.format("YYYY-MM-DD")
        : null,
      maxHoursPerWeek: Number(hour?.maximum),
      minHoursPerWeek: Number(hour?.minimum),
      locationNbr: storeData?.locationInfo?.locationNbr,
      loginName: storeData?.loginName,
    };

    const dispatchOperation =
      preferenceEntryToEdit && !editingEnabled
        ? updatePreference(PreferUpdateObj)
        : preferenceEntryToEdit && editingEnabled
          ? updatePreference(PreferUpdateCurrentObj)
          : createPreference(PreferCreateObj);

    await dispatch(dispatchOperation)
      .then((action: any) => {
        setRequestSubmitted(true);
        const response = action.payload;
        if (response.data && response.errorResponse === null) {
          analytics?.trackEvent({
            name: `Success: Created Preference`,
            properties: {
              page: "Preferences",
              workflow: "Create",
              component: "Create",
            },
          });
          localStorage.setItem("requestSubmitted", "success");
          setIsSubmitClicked(false);
          setIsSubmitSuccessful(true);
          void dispatch(getPreferences());
        } else {
          analytics?.trackEvent({
            name: `Error: Failed to create Preference`,
            properties: {
              page: "Preferences",
              workflow: "Create",
              component: "Create",
              error: response,
            },
          });
          localStorage.setItem("requestSubmitted", "fail");
          setIsSubmitClicked(false);
          setIsSubmitSuccessful(false);
        }
      })
      .catch(() => {
        setRequestSubmitted(true);
        setIsSubmitClicked(false);
        setIsSubmitSuccessful(false);
        void dispatch(getPreferences());
        localStorage.setItem("requestSubmitted", "fail");
      });
  };

  useEffect(() => {
    if (startDate) {
      const currenWeekMonday = dayjs().startOf("week")?.format("YYYY-MM-DD");

      const PreferCreateObj = {
        employeeId: Number(localStorage.getItem("userEmployeeId")),
        attributeId: Number(attributeId),
        start: dayjs(startDate)?.format("YYYY-MM-DD"),
        end: null,
        maxHoursPerWeek: Number(hour?.maximum),
        minHoursPerWeek: Number(hour?.minimum),
        locationNbr: storeData?.locationInfo?.locationNbr,
        loginName: storeData?.loginName,
      };
      const PreferUpdateObj = {
        employeeScheduleConstraintId: preferenceEntryToEdit?.empConstraintId,
        attributeAssignmentId: activePreference?.attributeAssignmentId,
        employeeId: Number(localStorage.getItem("userEmployeeId")),
        attributeId: Number(attributeId),
        start: moment.utc(startDate)?.format("YYYY-MM-DD"),
        end: preferenceEntryToEdit?.existingEndDate
          ? moment
              .utc(preferenceEntryToEdit.existingEndDate)
              ?.format("YYYY-MM-DD")
          : null,
        maxHoursPerWeek: Number(hour?.maximum),
        minHoursPerWeek: Number(hour?.minimum),
        locationNbr: storeData?.locationInfo?.locationNbr,
        loginName: storeData?.loginName,
      };
      const PreferUpdateCurrentObj = {
        employeeScheduleConstraintId: preferenceEntryToEdit?.empConstraintId,
        attributeAssignmentId: activePreference?.attributeAssignmentId,
        employeeId: Number(localStorage.getItem("userEmployeeId")),
        attributeId: Number(attributeId),
        start: currenWeekMonday,
        end: preferenceEntryToEdit?.existingEndDate
          ? dayjs(preferenceEntryToEdit.existingEndDate)?.format("YYYY-MM-DD")
          : null,
        maxHoursPerWeek: Number(hour?.maximum),
        minHoursPerWeek: Number(hour?.minimum),
        locationNbr: storeData?.locationInfo?.locationNbr,
        loginName: storeData?.loginName,
      };

      const dispatchOperation =
        preferenceEntryToEdit && !editingEnabled
          ? updatePreference(PreferUpdateObj)
          : preferenceEntryToEdit && editingEnabled
            ? updatePreference(PreferUpdateCurrentObj)
            : createPreference(PreferCreateObj);

      const handleCreateRetry = async () => {
        setShowLoader(true);
        await dispatch(dispatchOperation)
          .then((action: any) => {
            const response = action.payload;
            if (response.data && response.errorResponse === null) {
              analytics?.trackEvent({
                name: `Success: Created Preference on Retry`,
                properties: {
                  page: "Preferences",
                  workflow: "Create",
                  component: "Create",
                },
              });
              localStorage.setItem("requestSubmitted", "success");
              setShowLoader(false);
              setIsResubmitClicked(false);
              setIsSubmitSuccessful(true);
              void dispatch(getPreferences());
            } else {
              analytics?.trackEvent({
                name: `Error: Failed to create Preference`,
                properties: {
                  page: "Preferences",
                  workflow: "Create",
                  component: "Create",
                  error: response,
                },
              });
              localStorage.setItem("requestSubmitted", "fail");
              setShowLoader(false);
              setIsResubmitClicked(false);
              setIsSubmitSuccessful(false);
            }
            if (retryAttemptPreferences >= 3) {
              analytics?.trackEvent({
                name: `Error: Failed to create Preferences more than 3 times`,
                properties: {
                  page: "Preferences",
                  workflow: "Create",
                  component: "Create",
                  error: response,
                },
              });
              throw Error;
            }
            return;
          })
          .catch((e: any) => {
            analytics?.trackEvent({
              name: `Error: Failed to create Preference`,
              properties: {
                page: "Preferences",
                workflow: "Create",
                component: "Create",
                error: e,
              },
            });
            setShowLoader(false);
            setIsResubmitClicked(false);
            if (retryAttemptPreferences >= 3) {
              setMaxResubmitAttemptsReached(true);
              setIsSubmitSuccessful(false);
              localStorage.setItem("requestSubmitted", "fail");
              void dispatch(getPreferences());
            }
          });
      };
      void handleCreateRetry();
    }
  }, [retryAttemptPreferences]);

  return (
    <>
      <CustomDrawer
        anchor={"bottom"}
        toggleDrawer={toggleDrawer}
        drawerClass={"drawer bottom"}
        closeBtnClass={"close"}
        drawerTitle={
          preferenceEntryToEdit?.empConstraintId
            ? `${translate("CURRENT_PREFERRED_HOURS")}`
            : `${translate("NEW_PREFERRED_HOURS")}`
        }
        titleClass={
          preferenceEntryToEdit?.empConstraintId
            ? "drawer-title current-title"
            : "drawer-title"
        }
        onCustomAction={() => {
          clickClose();
        }}
        page={"preferences"}
        preferenceError={""}
      >
        {windowWidth < smallDesktop ? (
          <>
            {!isRequestSubmitted && (
              <>
                <Grid item container xs={12} alignItems="center">
                  <Step
                    stepTitle={translate("START_DATE")}
                    setNextStep={setNextStep}
                    nextStep={nextStep}
                    stepClass={`Step Step1 ${!nextStep ? "active" : "completed"}`}
                    step1={true}
                    isStartDateExist={!!preferenceEntryToEdit}
                    isRequestSubmitted={isRequestSubmitted}
                  />
                </Grid>

                <Grid item xs={12}>
                  {nextStep && startDate ? (
                    <DateHeader
                      startDate={startDate}
                      onClick={() => {
                        analytics?.trackEvent({
                          name: "Return to date selection",
                          properties: {
                            page: "Preferences",
                            workflow: "Create",
                            component: "DateHeader",
                          },
                        });
                        setNextStep(!nextStep);
                      }}
                      endDate={endDate}
                      isStartDateExist={!!preferenceEntryToEdit}
                    />
                  ) : (
                    <DatePick
                      showCalendar={showCalendar}
                      setShowCalendar={setShowCalendar}
                      startDate={isStartDateDisabled ? undefined : startDate}
                      handleDateChange={handleDateChange}
                      onStartDateDisabled={setIsStartDateDisabled}
                      isSelectedDayMonday={isSelectedDayMonday}
                      setIsSelectedDayMonday={setIsSelectedDayMonday}
                      isStartDateExist={!!preferenceEntryToEdit}
                      lastPreferredDate={lastPreferredDate}
                    />
                  )}
                </Grid>
              </>
            )}
            {!nextStep && !isRequestSubmitted && (
              <Grid item xs={12}>
                <CustomBtn
                  text={translate("NEXT")}
                  onClick={() => {
                    analytics?.trackEvent({
                      name: "Next button clicked",
                      properties: {
                        page: "Availability",
                        workflow: "Create",
                        component: "Next Button",
                      },
                    });
                    setNextStep(!nextStep);
                  }}
                  className="next-btn secondary-btn"
                  disabled={!startDate || isSelectedDayMonday}
                />
              </Grid>
            )}
            {!isRequestSubmitted && (
              <Grid xs={12} item>
                <CustomDivider
                  orientation="vertical"
                  dividerClass={!nextStep ? "active" : ""}
                />
              </Grid>
            )}
            {!isRequestSubmitted && (
              <Grid item xs={12}>
                <Step
                  stepTitle={translate("PREFERRED_HOURS")}
                  setNextStep={setNextStep}
                  nextStep={nextStep}
                  stepClass={`Step Step2 ${nextStep && "active"}`}
                  step1={false}
                  isRequestSubmitted={isRequestSubmitted}
                />
              </Grid>
            )}
            {!isSubmitSuccessful && nextStep && isRequestSubmitted && (
              <Grid item xs={12}>
                <ResultMessage
                  title={translate("PREFERRED_HOURS_POST_FAILURE_MSG")}
                  subTitle={
                    maxResubmitAttemptsReached
                      ? translate("TRY_AGAIN_LATER_PREFERENCE")
                      : translate("RESUBMIT_MSG")
                  }
                  error={true}
                  success={false}
                  onClick={() => {
                    analytics?.trackEvent({
                      name: `Retry Submit`,
                      properties: {
                        page: "Preferences",
                        workflow: "Create",
                        component: "Result Message",
                      },
                    });
                    if (maxResubmitAttemptsReached) {
                      clickClose();
                    } else {
                      dispatch(
                        setRetryAttemptPreferences(retryAttemptPreferences + 1),
                      );
                      setIsResubmitClicked(true);
                    }
                  }}
                  open
                  buttonText={
                    maxResubmitAttemptsReached
                      ? translate("CLOSE")
                      : translate("RESUBMIT")
                  }
                  buttonDisabled={isResubmitClicked}
                  showLoader={!maxResubmitAttemptsReached && showLoader}
                />
              </Grid>
            )}
            {isSubmitSuccessful && nextStep && isRequestSubmitted && (
              <Grid item xs={12}>
                <ResultMessage
                  title={translate("SUCCESS")}
                  subTitle={translate("PREFERRED_HOURS_POST_SUCCESS_MSG")}
                  error={false}
                  success={true}
                  onClick={() => {
                    analytics?.trackEvent({
                      name: `Close button click`,
                      properties: {
                        page: "Preferences",
                        workflow: "Create",
                        component: "Result Message",
                      },
                    });
                    clickClose();
                  }}
                  open
                  buttonText={translate("CLOSE")}
                  buttonDisabled={isResubmitClicked}
                  showLoader={showLoader}
                />
              </Grid>
            )}
            {nextStep && !isRequestSubmitted && (
              <>
                <Grid xs={12} item>
                  <Stack className="mb-1">
                    <TypoGraphy
                      variant="body1"
                      typeClass="regular-font primary-text-hover"
                      id="minimumValueText"
                    >
                      {`${translate("MINIMUM_PREFERRED_HRS_MSG")} ${storeData.minPreferredHours} ${translate("HRS/WK")}`}
                    </TypoGraphy>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <PreferredCard
                    handleChange={handleChange}
                    hour={hour}
                    preferredHours={preferredHours}
                    isHourUpdated={isHourUpdated}
                    totalHours={totalHours}
                    validation150={validation150}
                    empConstraintId={preferenceEntryToEdit?.empConstraintId}
                    activePreference={activePreference}
                    pendingData={pendingData}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomBtn
                    text={translate("CONFIRM")}
                    onClick={() => {
                      analytics?.trackEvent({
                        name: `Confirm button click`,
                        properties: {
                          page: "Preferences",
                          workflow: "Create",
                          component: "Confirm button",
                        },
                      });
                      void handleConfirm();
                    }}
                    className="confirm-btn secondary-btn"
                    disabled={
                      isSubmitClicked ||
                      !isHourUpdated ||
                      !hour?.prefer ||
                      !hour?.maximum ||
                      !hour?.minimum ||
                      (hour?.prefer * Number(validation150) > totalHours &&
                        totalHours !== 0)
                    }
                    loading={isSubmitClicked}
                    circularProgressClass="progress"
                  />
                </Grid>
              </>
            )}
          </>
        ) : (
          <Grid item container xs={12} direction="row" alignItems="stretch">
            <Grid className="leftPane" tabIndex={-1}>
              <Grid container item xs={12}>
                <TypoGraphy
                  variant="h1"
                  component="h1"
                  typeClass="drawer-title"
                >
                  {preferenceEntryToEdit
                    ? translate("CURRENT_PREFERRED_HOURS")
                    : translate("NEW_PREFERRED_HOURS")}
                </TypoGraphy>
              </Grid>
              <Grid item container xs={12} alignItems="center">
                <Step
                  stepTitle={translate("START_DATE")}
                  setNextStep={setNextStep}
                  nextStep={nextStep}
                  stepClass={`Step Step1 ${!nextStep ? "active" : "completed"}`}
                  step1={true}
                  isStartDateExist={!!preferenceEntryToEdit}
                  isRequestSubmitted={isRequestSubmitted}
                />
              </Grid>
              <Grid item xs={12}>
                {nextStep && startDate && (
                  <DateHeader
                    startDate={startDate}
                    onClick={() => {
                      analytics?.trackEvent({
                        name: "Return to date selection",
                        properties: {
                          page: "Preferences",
                          workflow: "Create",
                          component: "DateHeader",
                        },
                      });
                      setNextStep(!nextStep);
                    }}
                    isStartDateExist={!!preferenceEntryToEdit}
                    endDate={endDate}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <CustomDivider
                  orientation="vertical"
                  dividerClass={!nextStep ? "active" : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <Step
                  stepTitle={translate("PREFERRED_HOURS")}
                  setNextStep={setNextStep}
                  nextStep={nextStep}
                  stepClass={`Step Step2 ${nextStep && "active"}`}
                  step1={false}
                  isRequestSubmitted={isRequestSubmitted}
                />
              </Grid>
            </Grid>

            <Grid className="rightPane" tabIndex={-1}>
              <Grid container item xs={12} justifyContent="flex-end">
                <IconButton
                  onClick={() => {
                    analytics?.trackEvent({
                      name: "Close create drawer click",
                      properties: {
                        page: "Preferences",
                        workflow: "Create",
                        component: "Close button",
                      },
                    });
                    clickClose();
                  }}
                  className="close"
                  aria-label={translate("CLOSE_PREFERENCES_DRAWER")}
                >
                  <CloseRounded />
                </IconButton>
              </Grid>
              <Grid className="rightPanelItem">
                <SimpleBar className="rightPanelHeight" tabIndex={-1}>
                  <Grid item className="rightPanelItems">
                    {!nextStep && (
                      <>
                        <Grid xs={12} item>
                          <TypoGraphy
                            variant="h1"
                            typeClass="regular-font section-title"
                          >
                            {translate("START_DATE")}
                          </TypoGraphy>
                        </Grid>
                        <DatePick
                          showCalendar={showCalendar}
                          setShowCalendar={setShowCalendar}
                          startDate={
                            isStartDateDisabled ? undefined : startDate
                          }
                          handleDateChange={handleDateChange}
                          onStartDateDisabled={setIsStartDateDisabled}
                          isSelectedDayMonday={isSelectedDayMonday}
                          setIsSelectedDayMonday={setIsSelectedDayMonday}
                          isStartDateExist={!!preferenceEntryToEdit}
                          lastPreferredDate={lastPreferredDate}
                        />
                        <Grid item xs={12} textAlign="right">
                          <CustomBtn
                            text={translate("NEXT")}
                            onClick={() => {
                              analytics?.trackEvent({
                                name: "Next button click",
                                properties: {
                                  page: "Preferences",
                                  workflow: "Create",
                                  component: "Next button",
                                },
                              });
                              setNextStep(!nextStep);
                            }}
                            className={`next-btn secondary-btn ${!showCalendar && "bottom-pref"}`}
                            disabled={!startDate || isSelectedDayMonday}
                          />
                        </Grid>
                      </>
                    )}
                    {!isSubmitSuccessful && nextStep && isRequestSubmitted && (
                      <ResultMessage
                        title={translate("PREFERRED_HOURS_POST_FAILURE_MSG")}
                        subTitle={
                          maxResubmitAttemptsReached
                            ? translate("TRY_AGAIN_LATER_PREFERENCE")
                            : translate("RESUBMIT_MSG")
                        }
                        error={true}
                        success={false}
                        onClick={() => {
                          analytics?.trackEvent({
                            name: `Retry Submit`,
                            properties: {
                              page: "Preferences",
                              workflow: "Create",
                              component: "Result Message",
                            },
                          });
                          if (maxResubmitAttemptsReached) {
                            clickClose();
                          } else {
                            dispatch(
                              setRetryAttemptPreferences(
                                retryAttemptPreferences + 1,
                              ),
                            );
                            setIsResubmitClicked(true);
                          }
                        }}
                        open
                        buttonText={
                          maxResubmitAttemptsReached
                            ? translate("CLOSE")
                            : translate("RESUBMIT")
                        }
                        buttonDisabled={isResubmitClicked}
                        showLoader={!maxResubmitAttemptsReached && showLoader}
                      />
                    )}
                    {isSubmitSuccessful && nextStep && isRequestSubmitted && (
                      <ResultMessage
                        title={translate("SUCCESS")}
                        subTitle={translate("PREFERRED_HOURS_POST_SUCCESS_MSG")}
                        error={false}
                        success={true}
                        onClick={() => {
                          analytics?.trackEvent({
                            name: `Retry Close`,
                            properties: {
                              page: "Preferences",
                              workflow: "Create",
                              component: "Result Message",
                            },
                          });
                          clickClose();
                        }}
                        open
                        buttonText={translate("CLOSE")}
                        buttonDisabled={isResubmitClicked}
                        showLoader={showLoader}
                      />
                    )}
                    {nextStep && !isRequestSubmitted && (
                      <>
                        <Grid xs={12} item>
                          <TypoGraphy
                            variant="h1"
                            typeClass="regular-font section-title"
                          >
                            {translate("PREFERRED_HOURS")}
                          </TypoGraphy>
                        </Grid>
                        <Grid xs={12} item>
                          <Stack className="mb-1">
                            <TypoGraphy
                              variant="body1"
                              typeClass="regular-font primary-text-hover"
                              id="minimumValueText"
                            >
                              {`${translate("MINIMUM_PREFERRED_HRS_MSG")} ${storeData.minPreferredHours} ${translate("HRS/WK")}`}
                            </TypoGraphy>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <PreferredCard
                            handleChange={handleChange}
                            hour={hour}
                            preferredHours={preferredHours}
                            isHourUpdated={isHourUpdated}
                            totalHours={totalHours}
                            validation150={validation150}
                            empConstraintId={
                              preferenceEntryToEdit?.empConstraintId
                            }
                            activePreference={activePreference}
                            pendingData={pendingData}
                          />
                        </Grid>
                        <Grid item xs={12} textAlign="right">
                          <CustomBtn
                            text={translate("CONFIRM")}
                            onClick={() => {
                              analytics?.trackEvent({
                                name: `Confirm button click`,
                                properties: {
                                  page: "Preferences",
                                  workflow: "Create",
                                  component: "Confirm button",
                                },
                              });
                              void handleConfirm();
                            }}
                            className="confirm-btn secondary-btn"
                            disabled={
                              isSubmitClicked ||
                              !isHourUpdated ||
                              !hour?.prefer ||
                              !hour?.maximum ||
                              !hour?.minimum ||
                              (hour?.prefer * Number(validation150) >
                                totalHours &&
                                totalHours !== 0)
                            }
                            loading={isSubmitClicked}
                            circularProgressClass="progress"
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </SimpleBar>
              </Grid>
            </Grid>
          </Grid>
        )}
      </CustomDrawer>

      {openConfirmModal && (
        <ConfirmDialog
          openConfirmModal={openConfirmModal}
          closeModal={closeConfirmModal}
          handleConfirmModal={() => {
            toggleDrawer(false);
            clearStateValue();
          }}
        />
      )}
    </>
  );
};

export default Create;
