import { Typography, Button, Dialog } from "@mui/material";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { setInfoClicked, setIsNewUser } from "../../../features/metaReducer";
import { useAppDispatch, useAppSelector } from "../../../redux/store";

const NewUserDialog = () => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { infoClicked, isNewUser } = useAppSelector((state: any) => state.meta);

  const exitDialog = () => {
    dispatch(setInfoClicked(false));
    const empId = Number(localStorage.getItem("userEmployeeId"));
    localStorage.setItem("userLoggedIn", JSON.stringify(empId));
    dispatch(setIsNewUser(false));
  };

  return (
    <Dialog open={infoClicked || isNewUser} className="dialog">
      <div className={"box1"}>
        <Typography id="modal-title">{translate("WELCOME_TEXT")}</Typography>
        <Typography id="subtitle">{translate("POPUP_MSG_HEADER")}</Typography>
      </div>
      <div className="box2">
        <div className={"scrollableDiv"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography id="step">{translate("STEP1")}</Typography>
            <Typography id="info-header">
              {translate("SUBMIT_AVAILABILITY_INFO_HEADER")}
            </Typography>
            <Typography id="explanation">
              {translate("AVAILABILITY_EXPLANATION")}
            </Typography>
            <ul className="list">
              <li>
                {
                  <Typography id="list-text">
                    {translate("AVAILABILITY_SUBMISSION_DETAILS_PART1")}
                  </Typography>
                }
              </li>
              <li>
                {
                  <Typography id="list-text">
                    {translate("AVAILABILITY_SUBMISSION_DETAILS_PART2")}
                  </Typography>
                }
              </li>
              <li>
                {
                  <Typography id="list-text">
                    {translate("AVAILABILITY_SUBMISSION_DETAILS_PART3")}
                  </Typography>
                }
              </li>
            </ul>

            <span className="separator"></span>
            <Typography id="step">{translate("STEP2")}</Typography>
            <Typography id="info-header">
              {translate("SUBMIT_PREFERENCES_INFO_HEADER")}
            </Typography>
            <Typography id="explanation">
              {translate("PREFERENCES_EXPLANATION")}
            </Typography>
            <ul className="list">
              <li>
                {
                  <Typography id="list-text">
                    {translate("PREFERENCES_SUBMISSION_DETAILS_PART1")}
                  </Typography>
                }
              </li>
              <li>
                {
                  <Typography id="list-text">
                    {translate("PREFERENCES_SUBMISSION_DETAILS_PART2")}
                  </Typography>
                }
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="box3">
        <Typography id="footer">{translate("POPUP_MSG_FOOTER")}</Typography>
      </div>
      <div className="box4">
        <Button id="button" onClick={exitDialog} tabIndex={0}>
          <Typography id="button-text">{translate("GET_STARTED")}</Typography>
        </Button>
      </div>
    </Dialog>
  );
};

export default NewUserDialog;
