import { Grid } from "@mui/material";
import MainCard from "../../../common/Card";
import EditHours from "./EditHours";
import { useTranslation } from "react-i18next";
import MeetRequirement from "../../../shared/MeetRequirement";
import { InfoCircle, Check } from "../../../../styles/assets/svg/SVGIcons";
import { useEffect, useState } from "react";
import Warning from "../../../../styles/assets/svg/warning-icon.svg";
import { PreferenceType } from "../constants";
import NoData from "../../../shared/NoData";
import { useAppSelector } from "../../../../redux/store";

interface Props {
  handleChange?: any;
  hour?: any;
  preferredHours?: any;
  isHourUpdated?: boolean;
  totalHours: number;
  validation150?: string;
  empConstraintId?: number;
  activePreference?: PreferenceType;
  pendingData?: any;
}

const PreferredCard: React.FC<Props> = ({
  handleChange,
  hour,
  preferredHours,
  isHourUpdated,
  totalHours,
  validation150,
  activePreference,
  pendingData,
}) => {
  const { t: translate } = useTranslation();
  const [minHourList, setMinHourList] = useState<any[]>([]);
  const [preferHourList, setPreferHourList] = useState<any[]>([]);
  const [maxHourList, setMaxHourList] = useState<any[]>([]);
  const { availabilityData } = useAppSelector(
    (state: any) => state.availabilityAll,
  );
  const [currentData, setCurrentData] = useState<any>();
  const [futureData, setFutureData] = useState<any>();

  const { storeData } = useAppSelector((state: any) => state?.userStoreData);
  const maxHour = storeData.maxPreferredHours;
  const minHour = storeData.minPreferredHours;

  useEffect(() => {
    if (availabilityData) {
      setCurrentData(availabilityData?.currentAvailability);
      setFutureData(availabilityData?.futureAvailabilities);
    }
  }, [availabilityData]);

  useEffect(() => {
    const preferredHoursData = preferredHours?.entities
      ?.map((entity: any) => ({
        value: parseInt(entity.code),
      }))
      ?.sort((a: any, b: any) => Number(a.value) - Number(b.value));

    // Limiting the list till max hours for a store.
    const preferredHoursMaxList = preferredHoursData?.filter(
      (item: any) => item.value <= maxHour && item.value >= minHour,
    );
    if (!currentData && futureData?.length === 0 && pendingData.length === 0) {
      setPreferHourList([preferredHoursMaxList[0]]);
    } else {
      setPreferHourList(preferredHoursMaxList);
    }
  }, [preferredHours, hour, currentData, futureData]);

  useEffect(() => {
    const data: any[] = [];
    if (hour?.prefer && hour?.prefer >= minHour) {
      for (let i = minHour; i <= hour?.prefer; i++) {
        data.push({
          value: i,
        });
      }
      setMinHourList(data);
    } else if (hour?.minimum !== 0) {
      data.push({ value: hour?.minimum });
      setMinHourList(data);
    }
  }, [preferHourList, hour]);

  useEffect(() => {
    const data: any[] = [];
    if (hour?.prefer && hour?.prefer >= minHour) {
      for (let i = hour?.prefer; i <= Number(maxHour); i++) {
        data.push({
          value: i,
        });
      }
      setMaxHourList(data);
    } else if (hour?.maximum !== 0) {
      data.push({ value: hour?.maximum });
      setMaxHourList(data);
    }
  }, [hour, preferHourList]);

  return (
    <>
      {preferHourList?.length === 0 ? (
        <NoData
          message={translate("PREFERRED_HOURS_ATTRIBUTES_ARE_NOT_AVAILABLE")}
        />
      ) : (
        <MainCard
          cardClass={"main-card"}
          contentClass={"card-content preference-card"}
        >
          <Grid
            item
            xs={12}
            container
            justifyContent={"space-between"}
            textAlign={"center"}
          >
            <>
              <EditHours
                label={translate("PREFERRED")}
                ariaLabel={translate("SELECT_PREFERRED_HOURS")}
                name="prefer"
                value={hour?.prefer}
                handleChange={handleChange}
                itemClass={
                  !isHourUpdated
                    ? "PreferredItem"
                    : hour?.prefer * Number(validation150) <= totalHours ||
                        (totalHours === 0 &&
                          !currentData &&
                          futureData.length === 0 &&
                          pendingData.length === 0)
                      ? "PreferredItem success"
                      : "PreferredItem error"
                }
                hourList={preferHourList}
              />
              <EditHours
                label={translate("MINIMUM")}
                ariaLabel={translate("SELECT_MINIMUM_HOURS")}
                name="minimum"
                value={hour?.minimum}
                handleChange={handleChange}
                itemClass={
                  !isHourUpdated ? "PreferredItem" : "PreferredItem success"
                }
                hourList={minHourList}
                activePreference={activePreference}
              />
              <EditHours
                label={translate("MAXIMUM")}
                ariaLabel={translate("SELECT_MAXIMUM_HOURS")}
                name="maximum"
                value={hour?.maximum}
                handleChange={handleChange}
                itemClass={
                  !isHourUpdated ? "PreferredItem" : "PreferredItem success"
                }
                hourList={maxHourList}
              />
            </>
          </Grid>
          {!isHourUpdated ? (
            <MeetRequirement
              title={translate("EDIT_PREFERRED_HOURS")}
              message={translate("EDIT_YOUR_HOUR_PREFERENCES")}
              Icon={<InfoCircle />}
            />
          ) : hour?.prefer * Number(validation150) <= totalHours ||
            (totalHours === 0 &&
              !currentData &&
              futureData.length === 0 &&
              pendingData.length === 0) ? (
            <MeetRequirement
              title={translate("MEETS_REQUIREMENTS")}
              message={translate("PLEASE_REVIEW_AND_SUBMIT")}
              Icon={<Check />}
              successIcon={true}
            />
          ) : (
            <MeetRequirement
              title={translate("DOES_NOT_MEET_REQUIREMENTS")}
              message={`${translate("PREFERRED_HOURS_1")} ${Math.ceil(
                hour?.prefer - totalHours * 0.66667,
              )} ${translate("PREFERRED_HOURS_2")}`}
              image={Warning}
              errorIcon={true}
            />
          )}
        </MainCard>
      )}
    </>
  );
};

export default PreferredCard;
