import { Grid } from "@mui/material";
import CopyRequest from "./CopyRequest";
import MarkDay from "./MarkDay";
import { useAppSelector } from "../../../../redux/store";
import AddComment from "./AddComment";
import { useState } from "react";
import { formatTimeForNewRequestTime } from "../../../../utils/durationCalculate";
import useViewportWidth from "../../../../utils/useViewportWidth";
import { smallDesktop } from "../../../GlobalConstants";

interface Props {
  handleCopyRequest: (checked: boolean) => void;
  comment: string;
  setComment: (comment: string) => void;
  updateComment: (comment: string) => void;
  weeklyDays: any;
  setWeeklyDays: (weeklyDays: any) => void;
  totalHours: any;
  setTotalHours: (totalHours: any) => void;
}

const RequestWrapper: React.FC<Props> = ({
  handleCopyRequest,
  comment,
  setComment,
  weeklyDays,
  setWeeklyDays,
  totalHours,
  setTotalHours,
}) => {
  const { availabilityData } = useAppSelector(
    (state: any) => state.availabilityAll,
  );
  const weekDays: string[] = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  const windowWidth = useViewportWidth();

  const currentAvailabilityData =
    availabilityData?.currentAvailability?.weeklyAvailabilityResponseList?.[0]
      ?.generalAvailability;
  const copyCurrentAvailability = useAppSelector(
    (state) => state.copyCurrentAvailability.copyCurrentAvailability,
  );

  const editPendingAvailability = useAppSelector(
    (state: any) => state.meta.editPendingAvailability,
  );
  const editPendingAvailabilityData = useAppSelector(
    (state: any) => state.meta.editPendingAvailabilityData,
  );

  const WeekDaysTime = weekDays.map((day: string) => {
    const dayAvailability = currentAvailabilityData?.find(
      (availability: { dayOfWeek: string }) => {
        const firstThreeLetters = availability.dayOfWeek.toUpperCase();
        return firstThreeLetters === day.toUpperCase();
      },
    );

    if (dayAvailability) {
      const generalAvailabilityTimings =
        dayAvailability?.generalAvailabilityTimings;

      if (
        generalAvailabilityTimings &&
        generalAvailabilityTimings?.length > 0
      ) {
        const firstTiming = generalAvailabilityTimings[0];

        return {
          startTime:
            firstTiming.startTimeOffset !== "Not Available"
              ? firstTiming.startTimeOffset?.slice(0, 5)
              : "00:00",
          endTime:
            firstTiming.endTimeOffset !== "Not Available"
              ? firstTiming.endTimeOffset?.slice(0, 5)
              : "00:00",
          dayOfWeek: dayAvailability.dayOfWeek?.toUpperCase(),
        };
      } else {
        return {
          startTime: "00:00",
          endTime: "00:00",
          dayOfWeek: dayAvailability.dayOfWeek?.toUpperCase(),
          generalAvailabilityTimings: true,
        };
      }
    }

    return {
      startTime: "00:00",
      endTime: "00:00",
      dayOfWeek: day.toUpperCase(),
    };
  });

  const [hoursWithWeek, setHoursWithWeek] = useState<any>([]);

  const updateTotalHours = (hours: any, weekIndex: number) => {
    const updatedHoursWithWeek = [...hoursWithWeek];

    const existingIndex = updatedHoursWithWeek?.findIndex(
      (item) => item.weekIndex === weekIndex,
    );
    if (existingIndex !== -1) {
      updatedHoursWithWeek[existingIndex].hours = hours;
    } else {
      updatedHoursWithWeek.push({ hours, weekIndex });
    }

    setHoursWithWeek(updatedHoursWithWeek);

    const sum = updatedHoursWithWeek
      .filter((item) => item.weekIndex === weekIndex)
      .reduce((acc, item) => acc + item.hours, 0);

    const formattedHours = formatTimeForNewRequestTime(sum?.toString());
    setTotalHours(formattedHours);
  };

  return (
    <Grid
      container
      alignItems="center"
      className={
        copyCurrentAvailability ? "request-content active" : "request-content"
      }
    >
      <Grid className="copy-data">
        <CopyRequest
          handleCopyRequest={handleCopyRequest}
          totalHours={totalHours}
        />
      </Grid>
      <Grid style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        {weekDays.map((item: any, index: number) => (
          <MarkDay
            key={item}
            dayName={item}
            generalAvailabilityTimings={
              WeekDaysTime[index]
                ? editPendingAvailability
                  ? editPendingAvailabilityData.availabilities[index]
                      .generalAvailabilityTimings!
                  : WeekDaysTime[index].generalAvailabilityTimings!
                : false
            }
            weeklyDays={weeklyDays}
            setWeeklyDays={setWeeklyDays}
            updateWeeklyDays={setWeeklyDays}
            updateTotalHours={(hours: any) => updateTotalHours(hours, index)}
          />
        ))}
        {windowWidth < smallDesktop && (
          <Grid xs={12} item>
            <AddComment
              comment={comment}
              setComment={setComment}
              updateComment={setComment}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default RequestWrapper;
