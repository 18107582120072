import dayjs from "dayjs";
import {
  DEFAULT_TIME_FOR_ALL_DAY_END_DATE,
  DEFAULT_TIME_FOR_ALL_DAY_POST,
  END_TIME_NOON,
  LAST_SELECTABLE_START_TIME,
  START_TIME,
  START_TIME_NOON,
} from "../components/pages/Availability/Create/Constant";
import {
  convertTo24HourWithSeconds,
  IdealShiftObject,
} from "./durationCalculate";

export interface WeeklyDay {
  id: null | string;
  dayOfWeek: string;
  weekNumber: number;
  generalAvailabilityTimings?:
    | {
        startTimeOffset: string | null;
        endTimeOffset: string | null;
        workingHours: null | string;
        allDayAvailable?: boolean;
        notAvailable?: null;
        preferredAvailabilityTimings: IdealShiftObject[];
      }[]
    | null;
}

/**
 * Return availability schema to post/put
 *
 * @param {*} defaultWeeklyDays
 * @param {*} updatedWeekDaysStore
 * @param {*} comment
 * @param {*} selectedStartDate
 * @param {*} selectedEndDate
 * @param {*} selectedRequestId
 * @param {boolean} cancelAvailability
 * @param {boolean} editPendingAvailability
 * @param {*} editPendingAvailabilityData
 * @returns {{ ...; }} availability Post Schema
 */
export const returnAvailabilityToCancelPostEdit = (
  defaultWeeklyDays: any,
  updatedWeekDaysStore: any,
  comment: any,
  selectedStartDate: any,
  selectedEndDate: any,
  selectedRequestId: any,
  cancelAvailability: boolean,
  editPendingAvailability: boolean,
  editPendingAvailabilityData: any,
) => {
  const fullWeeklyDays: WeeklyDay[] = defaultWeeklyDays?.map((day: any) => ({
    id: null,
    weekNumber: 1,
    dayOfWeek: day.dayOfWeek,
    generalAvailabilityTimings: [],
  }));

  updatedWeekDaysStore?.forEach((selectedDay: any) => {
    const index = fullWeeklyDays?.findIndex(
      (day) => day?.dayOfWeek === selectedDay?.dayOfWeek,
    );
    if (index !== -1) {
      fullWeeklyDays[index] = { ...fullWeeklyDays[index], ...selectedDay };
    }
  });

  const commentArray = comment !== "" ? [{ comment: comment }] : [];
  const postGeneralAvailability = fullWeeklyDays?.map((day) => {
    let timings: any[] = [];
    if (day.generalAvailabilityTimings) {
      timings = day.generalAvailabilityTimings?.map((timing) => ({
        dayOfWeek: day.dayOfWeek,
        weekNumber: day.weekNumber,
        generalAvailabilityTimings: [
          {
            startTimeOffset:
              timing?.allDayAvailable ?? !timing.startTimeOffset
                ? DEFAULT_TIME_FOR_ALL_DAY_END_DATE
                : convertTo24HourWithSeconds(timing.startTimeOffset),
            endTimeOffset:
              timing?.allDayAvailable ?? !timing.endTimeOffset
                ? DEFAULT_TIME_FOR_ALL_DAY_POST
                : convertTo24HourWithSeconds(timing.endTimeOffset),
            allDayAvailable: timing?.allDayAvailable,
            preferredAvailabilityTimings:
              timing?.preferredAvailabilityTimings ?? [],
          },
        ],
      }));
    }
    return timings;
  });
  const StartDateFormatted = dayjs(selectedStartDate)?.format("YYYY-MM-DD");
  const EndDateFormatted = selectedEndDate
    ? dayjs(selectedEndDate)?.format("YYYY-MM-DD")
    : selectedEndDate;

  const availabilityDataToBeCancelled = {
    status: "Cancelled",
    id: selectedRequestId,
    comments: commentArray,
    effectiveFrom: StartDateFormatted,
    endsAfter: EndDateFormatted,
    availabilities: postGeneralAvailability.flat(),
  };
  const postNewAvailability = {
    status: "Requested",
    comments: commentArray,
    effectiveFrom: StartDateFormatted,
    endsAfter: EndDateFormatted,
    availabilities: postGeneralAvailability.flat(),
  };
  const editPendingAvailabilityPutData = {
    status: "Requested",
    id: editPendingAvailabilityData.id,
    comments: commentArray,
    effectiveFrom: StartDateFormatted,
    endsAfter: EndDateFormatted,
    availabilities: postGeneralAvailability.flat(),
  };
  if (cancelAvailability) {
    return availabilityDataToBeCancelled;
  }
  if (editPendingAvailability) {
    return editPendingAvailabilityPutData;
  } else if (!cancelAvailability && !editPendingAvailability) {
    return postNewAvailability;
  }

  return availabilityDataToBeCancelled;
};

/**
 * Format time from string to hh:mm A
 * @param {any} timeString time string value
 * @returns {string} formatted time value in hh:mm A
 */
export const formatTime = (timeString: any) => {
  if (!/[ap]m/i?.test(timeString)) {
    const [hours, minutes] = timeString.split(":");
    const hoursInt = parseInt(hours, 10);
    const suffix = hoursInt >= 12 ? "PM" : "AM";
    const formattedHours = hoursInt % 12 || 12;
    return `${formattedHours}:${minutes} ${suffix}`;
  }
  return timeString;
};

/**
 * get Minutes from time
 * @param {string} time time string value
 * @param {any} analytics analytics object to send logs
 * @returns {number} minutes from the time
 */
export const getMinutes = (time: string, analytics: any) => {
  const parts = time?.split(":");
  if (parts?.length >= 2) {
    return parseInt(parts[1]);
  } else {
    analytics?.trackEvent({
      name: `Error: Invalid time format`,
      properties: {
        page: "Availability",
        workflow: "Create",
        component: "MarkDay",
      },
    });
    throw new Error("Invalid time format");
  }
};

/**
 * add 15 Minutes to start time
 * @param {string} time time string value
 * @returns {string} time string
 */
export const add15Minutes = (time: string) => {
  const [hoursStr, minutesStr, period] = time.split(/[:\s]+/);
  let hours = parseInt(hoursStr, 10);
  let minutes = parseInt(minutesStr, 10);

  if (time === START_TIME_NOON) {
    return END_TIME_NOON;
  }

  if (time === LAST_SELECTABLE_START_TIME) {
    return START_TIME;
  }

  minutes += 15;
  if (minutes >= 60) {
    hours += Math?.floor(minutes / 60);
    minutes %= 60;
  }

  hours %= 12;
  if (hours === 0) {
    hours = 12;
  }

  const formattedHours = hours?.toString().padStart(2, "0");
  const formattedMinutes = minutes?.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${period}`;
};

/**
 * Add Minutes with variable time
 *
 * @param {string} time
 * @param {number} minutesToAdd
 * @returns {*}
 */
export const addMinutes = (time: string, minutesToAdd: number) => {
  const [hoursStr, minutesStr, period] = time.split(/[:\s]+/);
  let hours = parseInt(hoursStr, 10);
  let minutes = parseInt(minutesStr, 10);

  if (time === START_TIME_NOON) {
    return END_TIME_NOON;
  }

  if (time === LAST_SELECTABLE_START_TIME) {
    return START_TIME;
  }

  minutes += minutesToAdd;
  if (minutes >= 60) {
    hours += Math?.floor(minutes / 60);
    minutes %= 60;
  }

  hours %= 12;
  if (hours === 0) {
    hours = 12;
  }

  const formattedHours = hours?.toString();
  const formattedMinutes = minutes?.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${period}`;
};

/**
 * Format time string without padStart for hours
 *
 * @param {string} time
 * @returns {string}
 */
export const formatTimeString = (time: string) => {
  const [hoursStr, minutesStr, period] = time.split(/[:\s]+/);
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  const formattedHours = hours?.toString();
  const formattedMinutes = minutes?.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${period}`;
};
